import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Globals } from 'src/app/globals';
import { ParameterKeys } from 'src/app/models/constants';
import { User } from 'src/app/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class FundAdminUserService {
  constructor(
    private httpClient: HttpClient,
    private globals: Globals
  ) { }

  async checkService() {
      const host = this.globals.EnvParam(ParameterKeys.HOST_API_LEGACY);
      const url = `${host}v1/user/current/services`;
      const headers = new HttpHeaders({
        SSOAccessMode: 'PROD' // Fixed Env PROD
      });

      return this.httpClient.get<User>(url, { headers }).toPromise();
    }

}
