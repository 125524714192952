<div class="dashboard-page container-fluid">
  <div class="row d-flex align-items-center"></div>

  <div class="row">
    <div
      class="col-12 mt-5 mb-3 d-flex justify-content-start flex-row"
    >
      <h1 class="display-3 title d-inline-block mb-0" translate>
        DashboardPage.Title
      </h1>

      <span
        class="ml-2 badge badge-xl badge-pill badge-primary"
        style="    margin: auto;"
        *ngIf="navsAvailable"
        >{{ navs.length }}</span
      >

      <app-dashboard-notification
        *ngIf="displayNotification"
        [display]="displayNotification"
        [navModificationResult]="navModificationResult"
        (hidden)="onNotificationHidden()"
      ></app-dashboard-notification>
    </div>
    <div class="col-12">
      <div
        class="ml-1"
        style="
          max-width: 550px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
        "
      >
        <div>
          <h6 class="text-secondary mb-1" translate>
            DashboardPage.NAVrejected
          </h6>
          <h3>{{ totalNavsRefused }}</h3>
        </div>
        <div>
          <h6 class="text-secondary mb-1" translate>
            DashboardPage.NAVvalidated
          </h6>
          <h3>{{ totalNavsValidated }}</h3>
        </div>
        <div>
          <h6 class="text-secondary mb-1" translate>
            DashboardPage.WaitingforNAV
          </h6>
          <h3>{{ totalNavsWaiting }}</h3>
        </div>
        <div>
          <h6 class="text-secondary mb-1" translate>
            DashboardPage.NAVToValidate
          </h6>
          <h3>{{ totalNavsToValidate }}</h3>
        </div>
      </div>
    </div>
  </div>

  <div class="component-container" *ngIf="navsAvailable">
    <div class="dashboard-widgets row">
      <div class="dashboard-column col-xl-9">
        <gca-last-navs-list
          class="dashboard-widget-100"
          [apiUrl]="fundServicesApiUrl"
          [inputData]="inputData"
          style="margin-top: 4px !important; margin-bottom: 4px !important;"
        ></gca-last-navs-list>
      </div>
      <div class="dashboard-column col-xl-3">
        <gca-daily-reports-list
          class="dashboard-widget-50"
          [apiUrl]="fundServicesApiUrl"
          [inputData]="inputData"
        ></gca-daily-reports-list>
        <gca-compliance-breaches-list
          class="dashboard-widget-50"
          [apiUrl]="fundServicesApiUrl"
          [inputData]="inputData"
        ></gca-compliance-breaches-list>
      </div>
    </div>
  </div>

  <div
    *ngIf="!navsAvailable"
    class="component-container"
    style="
      margin-left: -12px;
      margin-right: -12px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    "
  >
    <i
      class="icon icon-lg text-secondary"
      style="margin-top: 40px; font-size: 40px;"
      >check</i
    >
    <h5 class="text-secondary" style="margin-top: 8px;" translate>
      DashboardPage.NoNAVvalidationRequired
    </h5>
    <label class="text-secondary" style="font-weight: 600; margin-top: 10px;" translate>DashboardPage.NoNAVTodayMessage</label
    >
    <div>
      <button
        class="btn btn-lg btn-light"
        style="margin-top: 40px;"
        (click)="onClickViewFundDetails()"
        translate >
        DashboardPage.ViewFundDetails
      </button>
    </div>
  </div>
</div>
