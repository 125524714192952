/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard-notification.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./dashboard-notification.component";
var styles_DashboardNotificationComponent = [i0.styles];
var RenderType_DashboardNotificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardNotificationComponent, data: {} });
export { RenderType_DashboardNotificationComponent as RenderType_DashboardNotificationComponent };
export function View_DashboardNotificationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["aria-atomic", "true"], ["aria-live", "assertive"], ["role", "alert"], ["style", "position:absolute;top:40px;right:20px"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "toast-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "ml-2 mb-1 close"], ["data-dismiss", "toast"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "i", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["close"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "toast-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.className; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.message; _ck(_v, 8, 0, currVal_2); }); }
export function View_DashboardNotificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dashboard-notification", [], null, null, null, View_DashboardNotificationComponent_0, RenderType_DashboardNotificationComponent)), i1.ɵdid(1, 638976, null, 0, i2.DashboardNotificationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardNotificationComponentNgFactory = i1.ɵccf("app-dashboard-notification", i2.DashboardNotificationComponent, View_DashboardNotificationComponent_Host_0, { display: "display", navModificationResult: "navModificationResult" }, { hidden: "hidden" }, []);
export { DashboardNotificationComponentNgFactory as DashboardNotificationComponentNgFactory };
