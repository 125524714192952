import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Environment } from 'src/app/environments/environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var FundAdminNavService = /** @class */ (function () {
    function FundAdminNavService(httpClient) {
        this.httpClient = httpClient;
    }
    FundAdminNavService.prototype.getNavs = function () {
        var host = Environment.host_api_fundservices;
        var url = host + "navs";
        var headers = new HttpHeaders({});
        return this.httpClient.get(url, { headers: headers })
            .pipe(map(function (result) { return result.data; }));
    };
    FundAdminNavService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FundAdminNavService_Factory() { return new FundAdminNavService(i0.ɵɵinject(i1.HttpClient)); }, token: FundAdminNavService, providedIn: "root" });
    return FundAdminNavService;
}());
export { FundAdminNavService };
