import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-lagacy-iframe-renderer',
  templateUrl: './lagacy-iframe-renderer.component.html',
  styleUrls: ['./lagacy-iframe-renderer.component.scss']
})
export class LagacyIframeRendererComponent {

  @Input() iframeUrl: string;
  public loaded: boolean;
  @ViewChild('iframe', {static: false}) iframeRef: ElementRef;

  constructor() { }

  load() {
    if (this.iframeRef) {
      this.loaded = true;
    }
  }
}
