import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { Globals } from 'src/app/globals';

@Injectable({
    providedIn: 'root'
})
export class FundAdminWidgetsService {
    constructor(
        private httpClient: HttpClient,
        private globals: Globals
    ) { }

    private cdnhost = this.globals.Environment.host_cdn_widget;

    public async getManifest(): Promise<{ [name: string]: string }> {
      const url = `${this.cdnhost}manifest.json?t=${new Date().valueOf()}`;

     return this.httpClient.get<{ [name: string]: string }>(url) .toPromise(); 
    }
}
