import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

import { Environment } from '../../../environments/environment';
import { FundDetailsInputData } from '../../models/fund-details-input-data.model';

@Component({
  selector: 'app-fund-details-trades',
  templateUrl: './fund-details-trades.component.html',
  styleUrls: ['./fund-details-trades.component.scss']
})
export class FundDetailsTradesComponent implements OnInit, OnChanges {

  public fundServicesApiUrl = Environment.host_api_fundservices;
  @Input() public inputData: FundDetailsInputData;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
  }

}
