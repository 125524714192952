import { BehaviorSubject, Subject } from 'rxjs';
import { Environment } from './environments/environment';
import * as i0 from "@angular/core";
var Globals = /** @class */ (function () {
    function Globals() {
        this._appLoaded = false;
        this.appLoadedSource = new Subject();
        this.appLoaded$ = this.appLoadedSource.asObservable();
        this.errorSource = new Subject();
        this.error$ = this.errorSource.asObservable();
        this.environment = Environment;
        this.languagesupport = [];
        this.language$ = new BehaviorSubject('EN');
    }
    Object.defineProperty(Globals.prototype, "language", {
        get: function () {
            return this._language;
        },
        set: function (value) {
            this._language = value;
            this.language$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Globals.prototype, "appLoaded", {
        get: function () {
            return this._appLoaded;
        },
        set: function (value) {
            console.log('global apploaded: ' + value);
            this._appLoaded = value;
            this.appLoadedSource.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Globals.prototype.emitError = function (code) {
        this.appLoaded = false;
        this.errorSource.next(code || "-1" /* Undefined */);
    };
    Object.defineProperty(Globals.prototype, "Environment", {
        get: function () {
            return this.environment;
        },
        enumerable: true,
        configurable: true
    });
    Globals.prototype.EnvParam = function (key) {
        return this.environment[key];
    };
    Globals.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Globals_Factory() { return new Globals(); }, token: Globals, providedIn: "root" });
    return Globals;
}());
export { Globals };
