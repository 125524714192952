var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Globals } from 'src/app/globals';
import * as i0 from "@angular/core";
import * as i1 from "../../../globals";
import * as i2 from "@angular/common/http";
var FundAdminIframeService = /** @class */ (function () {
    function FundAdminIframeService(globals, httpClient) {
        this.globals = globals;
        this.httpClient = httpClient;
        this.refreshIframeSource = new Subject();
        this.refreshIframe$ = this.refreshIframeSource.asObservable();
    }
    FundAdminIframeService.prototype.changeLanguage = function (isoCulture) {
        return __awaiter(this, void 0, void 0, function () {
            var urlChangeLanguage, urlParams, url, headers;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        urlChangeLanguage = "/suite1/ChangeLanguage.aspx";
                        urlParams = "NewLanguage=" + isoCulture + "&time=" + '636955224400738847';
                        url = "" + this.globals.EnvParam("host_legacy" /* HOST_LEGACY */) + urlChangeLanguage + "?" + urlParams;
                        headers = new HttpHeaders({
                            SSOUID: this.globals.user.ssoUId // ,
                            // SSOAccessMode: 'PROD' // Fixed Env PROD
                        });
                        return [4 /*yield*/, this.httpClient.get(url, { headers: headers }).toPromise()];
                    case 1:
                        _a.sent();
                        this.refreshIframeSource.next(true);
                        return [2 /*return*/];
                }
            });
        });
    };
    FundAdminIframeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FundAdminIframeService_Factory() { return new FundAdminIframeService(i0.ɵɵinject(i1.Globals), i0.ɵɵinject(i2.HttpClient)); }, token: FundAdminIframeService, providedIn: "root" });
    return FundAdminIframeService;
}());
export { FundAdminIframeService };
