import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Globals } from 'src/app/globals';
import { DashboardInputData, NavInfo, NavModificationResult, ValidationStatus } from 'src/app/shared/models';
import { FundAdminNavService } from '../../../core/services';
import { Environment } from '../../../environments/environment';

@Component({
  selector: 'app-home',
  styleUrls: ['./dashboard.component.scss'],
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit, OnDestroy {

  public navApiUrl = Environment.host_api_nav;
  public fundServicesApiUrl = Environment.host_api_fundservices;
  public inputData: DashboardInputData;

  displayNotification = false;
  navModificationResult: NavModificationResult;

  private navSubscription: Subscription;
  public navs: NavInfo[] | null = null;

  constructor(
    private router: Router,
    private fundAdminNavService: FundAdminNavService,
    private globals: Globals
  ) {
  }

  get navsAvailable() {
    return this.navs && this.navs.length > 0;
  }

  get totalNavsRefused() {
    return this.navs ? this.navs.filter(navInfo => navInfo.navInfoDetails[0].validationStatus === ValidationStatus.Refused).length : 0;
  }

  get totalNavsValidated() {
    return this.navs ? this.navs.filter(navInfo => navInfo.navInfoDetails[0].validationStatus === ValidationStatus.Validated).length : 0;
  }

  get totalNavsWaiting() {
    return this.navs ?
      this.navs.filter(navInfo => navInfo.navInfoDetails[0].validationStatus === ValidationStatus.WaitingForValuation).length : 0;
  }

  get totalNavsToValidate() {
    return this.navs ?
      this.navs.filter(navInfo => navInfo.navInfoDetails[0].validationStatus === ValidationStatus.WaitingForManagerValidation).length : 0;
  }

  ngOnInit() {
    this.inputData = {
      lang: this.globals.language$.value
    };
    this.navSubscription = this.fundAdminNavService.getNavs().subscribe(navs => this.navs = navs);
  }

  ngOnDestroy() {
    this.navSubscription.unsubscribe();
  }

  @HostListener('gca-last-navs-list--fund-selected', ['$event.detail'])
  onNavsListFundSelected(eventDetail) {
    this.router.navigate(['/fund-details'], { state: { data: eventDetail } });
  }

  @HostListener('gca-last-navs-list--nav-modified', ['$event.detail'])
  onNavsListNavModified(eventDetail) {
    this.fundAdminNavService.getNavs().subscribe(navs => this.navs = navs);
    this.navModificationResult = eventDetail as NavModificationResult;
    this.displayNotification = true;
  }

  public onNotificationHidden() {
    this.displayNotification = false;
  }

  public onClickViewFundDetails() {
    this.router.navigate(['/fund-details']);
  }

}
