import { enableProdMode, NgZone } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Environment } from 'src/app/environments/environment';

import { AppModule } from './app/app.module';

import { setupSGWTConnect, SGWTConnect } from '@sgwt/connect-angular';
import { ParameterKeys } from 'src/app/models/constants';
import { Envs } from './app/models/constants';

import { start as singleSpaStart } from 'single-spa';
import { PlatformLocation } from '@angular/common';
import { getSingleSpaExtraProviders } from 'single-spa-angular';

export interface ISgwtConnectJWTClaims {
  sub: string;
}

export interface ISgwtConnectElement extends HTMLElement {
  sgwtConnect: SGWTConnect;
}

if (Environment.current === Envs.Prod) {
  enableProdMode();
}


singleSpaStart();


const sgwtConnectEndpoint =
  Environment.current === Envs.Prod || Environment.current === Envs.PProd
    ? `https://sso.sgmarkets.com/sgconnect`
    : `https://sgconnect-hom.fr.world.socgen/sgconnect`;

const scopes = Environment[ParameterKeys.SGCONNECT_SCOPE];
const defaultScopes = ['openid', 'profile', 'mail'];
const sgwtConnect = setupSGWTConnect({
  authorization_endpoint: sgwtConnectEndpoint,
  client_id: Environment[ParameterKeys.SGCONNECT_CLIENT_ID],
  scope: [...defaultScopes, ...scopes].join(' '),
  acr_values: Environment[ParameterKeys.SGCONNECT_ACR]
});

const widget = document.querySelector('sgwt-connect') as any;
if (widget) {
  // When the code is executed, the widget may not have been initialized. So, we need to check that, otherwise calling
  // `widget.setSgwtConnectInstance()` will throw an error.
  if (typeof widget.setSgwtConnectInstance === 'undefined') {
    // Widget is not initialized yet, so we will wait the event that indicates the widget is ready...
    // tslint:disable-next-line:no-shadowed-variable
    const handleSgwtConnectReady = () => {
      widget.setSgwtConnectInstance(sgwtConnect);
      // Remove the event listener
      widget.removeEventListener('sgwt-connect--ready', handleSgwtConnectReady);
    };

    widget.addEventListener('sgwt-connect--ready', handleSgwtConnectReady);
  } else {
    // Widget is initialized...
    widget.setSgwtConnectInstance(sgwtConnect);
  }
}

const wrapper = document.querySelector('sgwt-connect') as ISgwtConnectElement;
const sgwtConnectWrapper = wrapper.sgwtConnect;

if (sgwtConnectWrapper.isAuthorized()) {
  /*
   * Bootstrap our Angular app with a top level NgModule
   */
  const appId = 'container-app';
  platformBrowserDynamic(getSingleSpaExtraProviders())
    .bootstrapModule(AppModule)
    .then(module => {
      NgZone.isInAngularZone = () => {
      // @ts-ignore
          return window.Zone.current._properties[appId] === true;
      };
      const rootPlatformLocation = module.injector.get(PlatformLocation) as any;
      const rootZone = module.injector.get(NgZone);
      // tslint:disable-next-line:no-string-literal
      rootZone['_inner']._properties[appId] = true;
      rootPlatformLocation.setNgZone(rootZone);
  })
    .catch(err => console.log(err));
} else if (sgwtConnectWrapper.getAuthorizationError()) {
  document.body.innerHTML = `
   <div class="alert alert-danger" role="alert">
       Authorization error: ${sgwtConnectWrapper.getAuthorizationError()}.
   </div>
   `;
} else {
  sgwtConnectWrapper.requestAuthorization();
}
