/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./fund-details-cash.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./fund-details-cash.component";
var styles_FundDetailsCashComponent = [i0.styles];
var RenderType_FundDetailsCashComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FundDetailsCashComponent, data: {} });
export { RenderType_FundDetailsCashComponent as RenderType_FundDetailsCashComponent };
export function View_FundDetailsCashComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["fund-details-cash works!"]))], null, null); }
export function View_FundDetailsCashComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-fund-details-cash", [], null, null, null, View_FundDetailsCashComponent_0, RenderType_FundDetailsCashComponent)), i1.ɵdid(1, 114688, null, 0, i2.FundDetailsCashComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FundDetailsCashComponentNgFactory = i1.ɵccf("app-fund-details-cash", i2.FundDetailsCashComponent, View_FundDetailsCashComponent_Host_0, {}, {}, []);
export { FundDetailsCashComponentNgFactory as FundDetailsCashComponentNgFactory };
