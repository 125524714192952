/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reporting-pere.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../../node_modules/@sgssgreat/great-components/sgssgreat-great-components.ngfactory";
import * as i4 from "@sgssgreat/great-components";
import * as i5 from "./reporting-pere.component";
var styles_ReportingPereComponent = [i0.styles];
var RenderType_ReportingPereComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReportingPereComponent, data: {} });
export { RenderType_ReportingPereComponent as RenderType_ReportingPereComponent };
export function View_ReportingPereComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "reporting-pere-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "row"], ["style", "background: white"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "h1", [["class", "page-title"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(5, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["ReportingPeRe.Title"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "great-report-folder-viewer", [["reportFolder", "pere"]], null, null, null, i3.View_ɵk_0, i3.RenderType_ɵk)), i1.ɵdid(8, 245760, null, 0, i4.ɵk, [i4.ɵb, i4.ɵf], { reportFolder: [0, "reportFolder"] }, null)], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 5, 0, currVal_0); var currVal_1 = "pere"; _ck(_v, 8, 0, currVal_1); }, null); }
export function View_ReportingPereComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-reporting-pere", [], null, null, null, View_ReportingPereComponent_0, RenderType_ReportingPereComponent)), i1.ɵdid(1, 114688, null, 0, i5.ReportingPereComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReportingPereComponentNgFactory = i1.ɵccf("app-reporting-pere", i5.ReportingPereComponent, View_ReportingPereComponent_Host_0, {}, {}, []);
export { ReportingPereComponentNgFactory as ReportingPereComponentNgFactory };
