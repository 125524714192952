import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { Environment } from '../../../environments/environment';
import { FundDetailsInputData } from '../../models/fund-details-input-data.model';

@Component({
  selector: 'app-fund-details-compliances',
  templateUrl: './fund-details-compliances.component.html',
  styleUrls: ['./fund-details-compliances.component.scss']
})
export class FundDetailsCompliancesComponent implements OnInit, OnChanges {

  public fundServicesApiUrl = Environment.host_api_fundservices;
  @Input() public inputData: FundDetailsInputData;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
  }

}
