import { HttpClient, HttpRequest } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Environment } from 'src/app/environments/environment';
import * as _ from 'lodash';
import { jQueryFactory } from './injection-tokens';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http);
}
// Export sgwtConnect factory (AOT requirement):
export function sgwtConnectFactory() {
    // noinspection CssInvalidHtmlTagReference
    var wrapper = document.querySelector('sgwt-connect');
    return wrapper.sgwtConnect;
}
// Export sgwtConnect predicate (AOT requirement):
export function sgwtConnectPredicate(_a) {
    var url = _a.url;
    url = (url || '').toLowerCase();
    var hosts = [
        Environment["host_api_front" /* HOST_API_FRONT */],
        Environment["host_api_great" /* HOST_API_GREAT */],
        Environment["host_api_legacy" /* HOST_API_LEGACY */]
    ];
    return hosts.some(function (ele) {
        if (ele) {
            return url.indexOf(ele.toLowerCase()) >= 0;
        }
    });
}
var ɵ0 = _, ɵ1 = jQueryFactory;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1 };
