var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
import { Route, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MenuItemType } from 'src/app/models';
import { LegacyIframeComponent } from 'src/app/shared/components/legacy-iframe/legacy-iframe.component';
import { FundAdminRouteGuard } from '../../guards';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var FundAdminRoutingService = /** @class */ (function () {
    function FundAdminRoutingService(router) {
        this.router = router;
        this.routeLoadedSource = new Subject();
        this.routeLoaded$ = this.routeLoadedSource.asObservable();
    }
    FundAdminRoutingService.prototype.initRouteLoaded = function () {
        this.routeLoadedSource.next(false);
    };
    FundAdminRoutingService.prototype.pushRoutes = function (menu) {
        var _a;
        var routes = this.buildRoutes(menu);
        this.replaceHomeWithDashboard(menu);
        (_a = this.router.config).unshift.apply(_a, __spread(routes));
        this.routeLoadedSource.next(true);
    };
    FundAdminRoutingService.prototype.popRoutes = function (menu) {
        var routes = this.buildRoutes(menu).map(function (r) { return r.path; });
        this.router.config = this.router.config.filter(function (route) { return routes.indexOf(route.path) < 0; });
        this.routeLoadedSource.next(true);
    };
    FundAdminRoutingService.prototype.replaceHomeWithDashboard = function (menus) {
        var hasDashboard = menus && menus.find(function (m) { return m.url && m.url.toLowerCase().includes("dashboard" /* Dashboard */.toLowerCase()); });
        // this.router.config.find(r => r.path === Routes_Paths.Dashboard);
        if (hasDashboard) {
            // inject redirect route
            var newDashboardRoute = {
                path: "" /* Homepage */,
                redirectTo: "dashboard" /* Dashboard */,
                canActivate: [FundAdminRouteGuard]
            };
            this.router.config = this.router.config.filter(function (r) { return r.path !== "" /* Homepage */ && r.path !== '**'; });
            this.router.config.unshift(newDashboardRoute);
            // Updates '**' route to redirect to Dashboard.
            var doubleStarRoute = {
                path: '**',
                redirectTo: "dashboard" /* Dashboard */,
                canActivate: [FundAdminRouteGuard]
            };
            this.router.config.push(doubleStarRoute);
        }
        else {
            // clean dashboard & funddetails routes
            this.router.config = this.router.config.filter(function (r) {
                return r.path !== "dashboard" /* Dashboard */ &&
                    r.path !== "fund-details" /* FundDetails */;
            });
        }
    };
    FundAdminRoutingService.prototype.buildRoutes = function (menu) {
        var e_1, _a;
        var routes = [];
        try {
            for (var menu_1 = __values(menu), menu_1_1 = menu_1.next(); !menu_1_1.done; menu_1_1 = menu_1.next()) {
                var item = menu_1_1.value;
                if (item.url && item.type === MenuItemType.Legacy) {
                    routes.push({
                        path: item.rewritedUrl,
                        component: LegacyIframeComponent,
                        canActivate: [FundAdminRouteGuard]
                    });
                }
                if (item.children && item.children.length > 0) {
                    routes.unshift.apply(routes, __spread(this.buildRoutes(item.children)));
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (menu_1_1 && !menu_1_1.done && (_a = menu_1.return)) _a.call(menu_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return routes;
    };
    FundAdminRoutingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FundAdminRoutingService_Factory() { return new FundAdminRoutingService(i0.ɵɵinject(i1.Router)); }, token: FundAdminRoutingService, providedIn: "root" });
    return FundAdminRoutingService;
}());
export { FundAdminRoutingService };
