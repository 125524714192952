import { Component, Input } from '@angular/core';

import { Environment } from '../../../environments/environment';
import { FundDetailsInputData } from '../../models/fund-details-input-data.model';

@Component({
  selector: 'app-fund-details-synthesis',
  templateUrl: './fund-details-synthesis.component.html',
  styleUrls: ['./fund-details-synthesis.component.scss']
})
export class FundDetailsSynthesisComponent  {

  public fundServicesApiUrl = Environment.host_api_fundservices;
  @Input() public inputData: FundDetailsInputData;

  constructor() { }
}
