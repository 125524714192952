import { HttpClient, HttpClientModule, HttpRequest } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GreatComponentsModule } from '@sgssgreat/great-components';
import { SGWTConnectModule } from '@sgwt/connect-angular';
import { Angulartics2Module } from 'angulartics2';
import { CookieService } from 'ngx-cookie-service';
import { Environment } from 'src/app/environments/environment';
import { ISgwtConnectElement } from '../main';
import { AppComponent } from './app.component';
import { AppRootingModule } from './app-routing.module';
import { DashboardComponent, IframePageComponent, LegacyIframeComponent, SplashComponent } from './shared/components';

import {
  FundAdminCultureService,
  FundAdminIframeService,
  FundAdminMenuService,
  FundAdminRoutingService,
  FundAdminUserService,
  FundAdminWidgetsService
} from './core/services';

import { Globals } from './globals';
import { SafePipe } from './safe.pipe';

import {
  FundDetailsComponent,
  LoadingComponent,
  NavbarComponent,
  ServerErrorComponent,
  UnauthorizedComponent
} from './shared/components/';

import * as _ from 'lodash';
import { ScriptsLoaderDirective } from './core/directives/scripts-loader/scripts-loader.directive';
import { ErrorsHandler } from './errors-handler';
import { JQUERY_TOKEN, jQueryFactory, LODASH_TOKEN } from './injection-tokens';
import { ParameterKeys } from './models/constants';
import { DashboardNotificationComponent } from './shared/components/dashboard-notification/dashboard-notification.component';
import { FundDetailsCashComponent } from './shared/components/fund-details-cash/fund-details-cash.component';
import { FundDetailsCompliancesComponent } from './shared/components/fund-details-compliances/fund-details-compliances.component';
import { FundDetailsForcedPricesComponent } from './shared/components/fund-details-forced-prices/fund-details-forced-prices.component';
import { FundDetailsNavControlComponent } from './shared/components/fund-details-nav-control/fund-details-nav-control.component';
// tslint:disable-next-line:max-line-length
import { FundDetailsStatementOfHoldingsComponent } from './shared/components/fund-details-statement-of-holdings/fund-details-statement-of-holdings.component';
import { FundDetailsSubsRedsComponent } from './shared/components/fund-details-subs-reds/fund-details-subs-reds.component';
import { FundDetailsSynthesisComponent } from './shared/components/fund-details-synthesis/fund-details-synthesis.component';
import { FundDetailsTradesComponent } from './shared/components/fund-details-trades/fund-details-trades.component';

import { LagacyIframeRendererComponent } from './shared/components/legacy-iframe/lagacy-iframe-renderer/lagacy-iframe-renderer.component';
import { ReportingPereComponent } from './shared/components/reporting-pere/reporting-pere.component';

import { SafeUrlPipe } from './shared/pipes/safe-url.pipe';
import { MicroFrontendRouteReuseStrategy } from './single-spa/route-reuse-strategy';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

// Export sgwtConnect factory (AOT requirement):
export function sgwtConnectFactory() {
  // noinspection CssInvalidHtmlTagReference
  const wrapper = document.querySelector('sgwt-connect') as ISgwtConnectElement;
  return wrapper.sgwtConnect;
}

// Export sgwtConnect predicate (AOT requirement):
export function sgwtConnectPredicate({ url }: HttpRequest<any>) {
  url = (url || '').toLowerCase();
  const hosts: string[] = [
    Environment[ParameterKeys.HOST_API_FRONT],
    Environment[ParameterKeys.HOST_API_GREAT],
    Environment[ParameterKeys.HOST_API_LEGACY]
  ];

  return hosts.some(ele => {
    if (ele) {
      return url.indexOf(ele.toLowerCase()) >= 0;
    }
  });
}

@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent,
    DashboardComponent,
    SplashComponent,
    NavbarComponent,
    LegacyIframeComponent,
    UnauthorizedComponent,
    ServerErrorComponent,
    ReportingPereComponent,
    SafePipe,
    FundDetailsComponent,
    FundDetailsSynthesisComponent,
    FundDetailsStatementOfHoldingsComponent,
    FundDetailsTradesComponent,
    FundDetailsSubsRedsComponent,
    FundDetailsCashComponent,
    FundDetailsForcedPricesComponent,
    FundDetailsCompliancesComponent,
    FundDetailsNavControlComponent,
    ScriptsLoaderDirective,
    DashboardNotificationComponent,
    IframePageComponent,
    LagacyIframeRendererComponent,
    SafeUrlPipe
  ],
  entryComponents: [LegacyIframeComponent],
  imports: [
    BrowserModule,
    AppRootingModule,
    HttpClientModule,
    SGWTConnectModule.forRoot(sgwtConnectFactory, sgwtConnectPredicate),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    Angulartics2Module.forRoot(),
    GreatComponentsModule.forRoot({
      apiBaseUrl: Environment[ParameterKeys.HOST_API_GREAT]
    })
  ],
  providers: [
    { provide: LODASH_TOKEN, useValue: _ },
    { provide: JQUERY_TOKEN, useFactory: jQueryFactory },
    { provide: ErrorHandler, useClass: ErrorsHandler },
    {
      provide: RouteReuseStrategy,
      useClass: MicroFrontendRouteReuseStrategy
    },
    Globals,
    Title,

    CookieService,

    FundAdminUserService,
    FundAdminCultureService,
    FundAdminMenuService,
    FundAdminRoutingService,
    FundAdminIframeService,
    FundAdminWidgetsService
  ],
  bootstrap: [
    AppComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})

export class AppModule {
}
