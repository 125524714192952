var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Angulartics2Piwik } from 'angulartics2/piwik';
import { TranslateService } from '@ngx-translate/core';
import { FundAdminCultureService, FundAdminMenuService, FundAdminRoutingService, FundAdminUserService } from './core/services';
import { Globals } from './globals';
import { BusinessLines } from './models/constants';
var AppComponent = /** @class */ (function () {
    function AppComponent(angulartics2Piwik, globals, titleService, translate, fundAdminUserService, fundAdminCultureService, fundAdminMenuService, fundAdminRoutingService, renderer2) {
        this.angulartics2Piwik = angulartics2Piwik;
        this.globals = globals;
        this.titleService = titleService;
        this.translate = translate;
        this.fundAdminUserService = fundAdminUserService;
        this.fundAdminCultureService = fundAdminCultureService;
        this.fundAdminMenuService = fundAdminMenuService;
        this.fundAdminRoutingService = fundAdminRoutingService;
        this.renderer2 = renderer2;
        this.appLoaded = false;
        this.errors = [];
    }
    AppComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.angulartics2Piwik.startTracking();
                // tslint:disable-next-line:arrow-parens
                this.appLoadedSubscription = this.globals.appLoaded$.subscribe(function (_) {
                    _this.appLoaded = _;
                });
                // tslint:disable-next-line:arrow-parens
                this.errorSubscription = this.globals.error$.subscribe(function (_) {
                    _this.errors = [_];
                });
                this.loadApp();
                this.titleService.setTitle(this.globals.EnvParam("apparence_site_title" /* APPARENCE_SITE_TITLE */));
                return [2 /*return*/];
            });
        });
    };
    AppComponent.prototype.loadApp = function () {
        return __awaiter(this, void 0, void 0, function () {
            var lang, user, menu, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 6]);
                        // sgwt application id
                        this.applicationId = this.globals.EnvParam("application_id" /* APPLICATION_ID */);
                        return [4 /*yield*/, this.fundAdminCultureService.initCulture()];
                    case 1:
                        lang = _a.sent();
                        this.translate.use(lang);
                        window.lang = lang;
                        this.globals.language = lang;
                        return [4 /*yield*/, this.fundAdminUserService.checkService()];
                    case 2:
                        user = _a.sent();
                        this.globals.user = user;
                        return [4 /*yield*/, this.fundAdminMenuService.buildMenu(lang)];
                    case 3:
                        menu = _a.sent();
                        this.fundAdminRoutingService.pushRoutes(menu);
                        // Set Flag AppLoaded
                        this.globals.appLoaded = true;
                        this.addAnalyticsWebComponent();
                        return [3 /*break*/, 6];
                    case 4:
                        e_1 = _a.sent();
                        return [4 /*yield*/, this.unauthorized()];
                    case 5:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.unauthorized = function () {
        return __awaiter(this, void 0, void 0, function () {
            var redirectUrl;
            return __generator(this, function (_a) {
                // Show unauthorized component
                if (!this.globals.user ||
                    this.globals.EnvParam("func_strict401" /* FUNC_STRICT401 */)) {
                    redirectUrl = '';
                    // tslint:disable-next-line:prefer-conditional-expression
                    if (this.globals.Environment.current === 2 /* PProd */ ||
                        this.globals.Environment.current === 3 /* Prod */) {
                        redirectUrl = "https://shared.sgmarkets.com/unauthorized/?redirectUri=" + location.protocol + "//" + window.location.hostname;
                    }
                    else {
                        redirectUrl = "https://shared-uat.fr.world.socgen/unauthorized/?redirectUri=" + location.protocol + "//" + window.location.hostname;
                    }
                    window.location.replace(redirectUrl);
                    // this.globals.emitError(ErrorCodes.Error401); // Global redirection sgmarket
                }
                return [2 /*return*/];
            });
        });
    };
    Object.defineProperty(AppComponent.prototype, "hasErrors", {
        get: function () {
            return this.errors.length !== 0;
        },
        enumerable: true,
        configurable: true
    });
    AppComponent.prototype.hasError = function (errCode) {
        var exsited = this.errors.some(function (err) { return errCode === err; });
        return exsited;
    };
    AppComponent.prototype.ngAfterViewInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.accessibilityInit()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.accessibilityInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var accebilityDict, businessLine;
            var _this = this;
            return __generator(this, function (_a) {
                accebilityDict = {
                    AssetServicing: {
                        fr: '/assets/accessibility-docs/SOCIETE-GENERALE-SG Markets Asset Servicing-Declaration-Accessibilite-2021-03.pdf',
                        en: '/assets/accessibility-docs/SOCIETE-GENERALE-SG Markets Asset Servicing-Declaration-Accessibility-2021-03.pdf',
                        it: '/assets/accessibility-docs/SOCIETE-GENERALE-SG Markets Asset Servicing-Declaration-Accessibility-2021-03.pdf'
                    },
                    FundAdministration: {
                        fr: '/assets/accessibility-docs/SOCIETE-GENERALE-SG Markets Fund Administration-Declaration-Accessibilite-2021-03.pdf',
                        en: '/assets/accessibility-docs/SOCIETE-GENERALE-SG Markets Fund Administration-Declaration-Accessibility-2021-03.pdf',
                        it: '/assets/accessibility-docs/SOCIETE-GENERALE-SG Markets Fund Administration-Declaration-Accessibility-2021-03.pdf'
                    },
                    SecuritiesLending: {
                        fr: '/assets/accessibility-docs/SOCIETE-GENERALE-SG Markets Securities Lending-Declaration-Accessibilite-2021-03.pdf',
                        en: '/assets/accessibility-docs/SOCIETE-GENERALE-SG Markets Securities Lending-Declaration-Accessibility-2021-03.pdf',
                        it: '/assets/accessibility-docs/SOCIETE-GENERALE-SG Markets Securities Lending-Declaration-Accessibility-2021-03.pdf'
                    }
                };
                businessLine = this.globals.EnvParam("businessLine" /* BUSINESS_LINE */);
                this.globals.language$.subscribe(function (lang) {
                    var accessibilityLink = accebilityDict[BusinessLines[businessLine]][lang];
                    _this.footer.nativeElement.accessibilityLink = accessibilityLink;
                });
                return [2 /*return*/];
            });
        });
    };
    AppComponent.prototype.onLegalNoticeDownload = function () {
        var legalNoticeTopicId = this.globals.Environment.helpcenter_topic_id_documents;
        var widget = document.querySelector('sgwt-help-center');
        if (widget) {
            legalNoticeTopicId ? widget.category(legalNoticeTopicId) : widget.open();
        }
    };
    /**
     * add sgwt-web-analytics component to body
     */
    AppComponent.prototype.addAnalyticsWebComponent = function () {
        // matomo config
        var matomoSiteId = this.globals.EnvParam("piwik_site_id" /* PIWIK_SITE_ID */);
        var matomoTrackUrl = this.globals.EnvParam("piwik_url" /* PIWIK_URL */);
        var element = document.createElement('sgwt-web-analytics', {});
        element.setAttribute("site-id", "" + matomoSiteId);
        element.setAttribute("base-url", "" + matomoTrackUrl);
        this.renderer2.appendChild(document.body, element);
    };
    AppComponent.prototype.ngOnDestroy = function () {
        this.appLoadedSubscription.unsubscribe();
        this.errorSubscription.unsubscribe();
    };
    return AppComponent;
}());
export { AppComponent };
