import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Globals } from '../../../globals';

@Component({
  selector: 'app-iframe-page',
  templateUrl: './iframe-page.component.html',
  styleUrls: ['./iframe-page.component.scss']
})
export class IframePageComponent implements OnInit {
  public resource: Observable<string>;
  public baseUrl: string;

  constructor(private route: ActivatedRoute, globals: Globals) {
    this.baseUrl = globals.Environment.host_legacy;
  }

  ngOnInit() {
    this.resource = this.route.queryParams.pipe(map(x => x.resource));
  }

}
