import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Globals } from 'src/app/globals';

@Injectable({
  providedIn: 'root'
})
export class FundAdminRouteGuard implements CanActivate {

  constructor(
    private globals: Globals,
    private router: Router
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    if (this.globals.appLoaded) {
      return of(true);
    }

    return this.globals.appLoaded$
          .pipe(map(x => {
            this.router.navigateByUrl(state.url);
            return true;
          }));
  }
}
