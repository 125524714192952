import { Directive, ElementRef, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';
import { BusinessLines, ParameterKeys } from 'src/app/models/constants';
import { FundAdminWidgetsService } from '../../services';

@Directive({
  selector: '[appScriptsLoader]'
})
export class ScriptsLoaderDirective implements OnInit {
  constructor(
    private globals: Globals,
    private fundAdminWidgetsService: FundAdminWidgetsService,
    el: ElementRef
  ) {}

  ngOnInit(): void {
    if (
      this.globals.EnvParam(ParameterKeys.BUSINESS_LINE) ===
      BusinessLines.FundAdministration
    ) {
      this.loadWidgets();
    }
  }

  private async loadWidgets() {
    const manifestJson = await this.fundAdminWidgetsService.getManifest();

    this.loadWidget(manifestJson['runtime.js']);
    this.loadWidget(manifestJson['common.js']);
    this.loadWidget(manifestJson['vendors.js']);

    for (const key in manifestJson) {
      if (key !== 'runtime.js' && key !== 'common.js' && key !== 'vendors.js') {
        const widget = manifestJson[key];
        this.loadWidget(widget);
      }
    }
  }

  private loadWidget(path: string) {
    const tagScript = document.createElement('script');
    tagScript.type = 'module';
    tagScript.defer = true;
    tagScript.src = `${this.globals.Environment.host_cdn_widget}${path}`;
    document.getElementsByTagName('head')[0].appendChild(tagScript);
  }
}
