

<div class='centered'  *ngIf='!loaded'>
  <div class="lds-dual-ring"></div>
  <span class="sr-only" translate>IFramLegacy.Loading</span>
</div>



<div class="iframe-container" *ngIf='iframeUrl' [style.display]="loaded ? 'block' : 'none' ">
  <iframe #iframe class='ifrm-gallery' [src]="iframeUrl | safeUrl" (load)='load()'></iframe>
</div>


