<app-lagacy-iframe-renderer *ngIf='resource | async; else notfound'
  [iframeUrl]="baseUrl + (resource| async)">
</app-lagacy-iframe-renderer>


<ng-template #notfound>
  <div class='message-holder'>
    <div class="alert alert-outline-primary" translate>IFramePage.NotFound</div>
  </div>
</ng-template>
