import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Globals } from 'src/app/globals';
import { Langs, ParameterKeys } from 'src/app/models/constants';

@Injectable({
  providedIn: 'root'
})
export class FundAdminCultureService {

  readonly SESSION_KEY_LANG = 'GFA_Lang';
  readonly DEFAULT_ID_CULTURE = Langs.en;
  readonly DEFAULT_ISO_CULTURE = 'en';

  private cultureSource = new Subject<string>();
  public culture$ = this.cultureSource.asObservable();

  private currentIdCulture: number;

  constructor(
    private globals: Globals,
    private httpClient: HttpClient
  ) {
  }

  public async initCulture(): Promise<string> {
    this.setCulture(await this.getCulture());
    const lang = this.convertToISOCulture(this.currentIdCulture);
    return Promise.resolve(lang);
  }

  public setCulture(isoCulture: string) {
    this.currentIdCulture = this.convertToIdCulture(isoCulture);
    this.cultureSource.next(isoCulture);
    return isoCulture;
  }

  public async getCulture(): Promise<string> {
    if (!this.currentIdCulture) {
      const host = this.globals.EnvParam(ParameterKeys.HOST_API_LEGACY);
      const url = `${host}v1/user/current/portalconfiguration`;
      const portalconfig = await this.httpClient.get<any>(url, {
        headers: { SSOAccessMode: 'PROD' }
      }).toPromise();
      this.currentIdCulture = this.convertToIdCulture(portalconfig.language);
    }
    return this.convertToISOCulture(this.currentIdCulture);
  }

  public convertToISOCulture(idCulture: number): string {
    const isoCulture = Object.keys(Langs).find(k => Langs[k] === idCulture);

    return isoCulture || this.DEFAULT_ISO_CULTURE;
  }

  public convertToIdCulture(isoCulture: string): number {
    const values = Object.keys(Langs).map(e => Langs[e]); // fallback/polyfill for IE11
    const idCulture = values.find(v => Langs[isoCulture] === v);

    return idCulture || this.DEFAULT_ID_CULTURE;
  }
}
