import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Globals } from 'src/app/globals';
import { ParameterKeys } from 'src/app/models/constants';

@Injectable({
  providedIn: 'root'
})
export class FundAdminIframeService {

  refreshIframeSource = new Subject<boolean>();
  public refreshIframe$ = this.refreshIframeSource.asObservable();

  constructor(
    private globals: Globals,
    private httpClient: HttpClient
  ) {
  }

  public async changeLanguage(isoCulture: string) {
    const urlChangeLanguage = `/suite1/ChangeLanguage.aspx`;
    const urlParams = `NewLanguage=${isoCulture}&time=${'636955224400738847'}`;
    const url = `${this.globals.EnvParam(ParameterKeys.HOST_LEGACY)}${urlChangeLanguage}?${urlParams}`;

    const headers = new HttpHeaders({
        SSOUID : this.globals.user.ssoUId // ,
        // SSOAccessMode: 'PROD' // Fixed Env PROD
      });

    await this.httpClient.get<any>(url, { headers }).toPromise();
    this.refreshIframeSource.next(true);
  }
}
