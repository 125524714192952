var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment';
import { Environment } from '../../../environments/environment';
import { FundDetailsTab } from '../../models';
import { Globals } from 'src/app/globals';
var FundDetailsComponent = /** @class */ (function () {
    function FundDetailsComponent(router, globals) {
        var _this = this;
        this.router = router;
        this.globals = globals;
        this.fundServicesApiUrl = Environment.host_api_fundservices;
        this.fundDetailsTab = FundDetailsTab;
        this.activeTab = FundDetailsTab.Synthesis;
        this.routerEventsSubscription = this.router.events.subscribe(function (event) {
            if (event instanceof NavigationEnd) {
                var data = _this.router.getCurrentNavigation().extras.state.data;
                _this.fundCode = data.fundCode;
                _this.pocketCode = data.pocketCode;
                _this.navDate = moment(data.navDate).format('YYYY-MM-DD');
            }
        });
    }
    FundDetailsComponent.prototype.ngOnInit = function () {
        this.inputData = {
            fundCode: '',
            pocketCode: '',
            keyNavSequence: -1,
            navDate: '',
            lang: this.globals.language$.value
        };
        this.datePickerInputData = {
            lang: this.globals.language$.value
        };
    };
    FundDetailsComponent.prototype.ngOnDestroy = function () {
        this.routerEventsSubscription.unsubscribe();
    };
    FundDetailsComponent.prototype.onClickSynthesis = function () {
        this.activeTab = FundDetailsTab.Synthesis;
    };
    FundDetailsComponent.prototype.onClickStatementOfHoldings = function () {
        this.activeTab = FundDetailsTab.StatemtentOfHoldings;
    };
    FundDetailsComponent.prototype.onClickTrades = function () {
        this.activeTab = FundDetailsTab.Trades;
    };
    FundDetailsComponent.prototype.onClickSubsReds = function () {
        this.activeTab = FundDetailsTab.SubsReds;
    };
    FundDetailsComponent.prototype.onClickCash = function () {
        this.activeTab = FundDetailsTab.Cash;
    };
    FundDetailsComponent.prototype.onClickForcedPrices = function () {
        this.activeTab = FundDetailsTab.ForcedPrices;
    };
    FundDetailsComponent.prototype.onClickCompliances = function () {
        this.activeTab = FundDetailsTab.Compliances;
    };
    FundDetailsComponent.prototype.onClickNavControl = function () {
        this.activeTab = FundDetailsTab.NavControl;
    };
    FundDetailsComponent.prototype.onFundSelectorValueChanged = function (eventDetail) {
        //const inputData = this.inputData;
        var fund = JSON.parse(eventDetail.fundCode);
        // inputData.fundCode = fund.fundCode;
        // inputData.pocketCode = fund.pocketCode;
        // this.inputData = { ...inputData };
        this.inputData.fundCode = fund.fundCode;
        this.inputData.pocketCode = fund.pocketCode;
        this.fundCode = fund.fundCode;
    };
    FundDetailsComponent.prototype.onNavDateSelectorValueChanged = function (eventDetail) {
        var inputData = this.inputData;
        var navDate = JSON.parse(eventDetail.navDate);
        inputData.navDate = navDate.navDate;
        inputData.keyNavSequence = navDate.keyNavSequence;
        this.inputData = __assign({}, inputData);
    };
    return FundDetailsComponent;
}());
export { FundDetailsComponent };
