import { OnInit, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { ValidationStatus } from 'src/app/shared/models';
var DashboardNotificationComponent = /** @class */ (function () {
    function DashboardNotificationComponent() {
        this.display = false;
        this.hidden = new EventEmitter();
    }
    DashboardNotificationComponent.prototype.ngOnInit = function () {
    };
    DashboardNotificationComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        var displayChange = changes["display"];
        if (displayChange && displayChange.currentValue) {
            // add a timeout to close automatically
            _.delay(function () { return _this.onClickClose(); }, 5000);
        }
    };
    Object.defineProperty(DashboardNotificationComponent.prototype, "displayClass", {
        get: function () {
            return this.display ? 'show' : 'hide';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardNotificationComponent.prototype, "colorClass", {
        get: function () {
            return this.navModificationResult && this.navModificationResult.statusCode >= 200 && this.navModificationResult.statusCode < 300
                ? 'toast-success'
                : 'toast-danger';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardNotificationComponent.prototype, "className", {
        get: function () {
            return "toast " + this.colorClass + " fade " + this.displayClass;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardNotificationComponent.prototype, "title", {
        get: function () {
            if (!this.navModificationResult) {
                return '';
            }
            if (this.navModificationResult.fundNames.length > 1) {
                return 'NAV validation result';
            }
            return this.navModificationResult.navStatus === ValidationStatus.Validated
                ? 'NAV successfully validated'
                : 'NAV successfully rejected';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardNotificationComponent.prototype, "message", {
        get: function () {
            if (!this.navModificationResult) {
                return '';
            }
            return "Your portfolio " + this.navModificationResult.fundNames[0] + " has been " + (this.navModificationResult.navStatus === ValidationStatus.Validated ? 'validated' : 'rejected') + ".";
        },
        enumerable: true,
        configurable: true
    });
    DashboardNotificationComponent.prototype.onClickClose = function () {
        this.display = false;
        this.hidden.emit(!this.display);
    };
    return DashboardNotificationComponent;
}());
export { DashboardNotificationComponent };
