import { BusinessLines, Envs } from 'src/app/models/constants';
import { EnvironmentModel } from '../models';

const env = Envs.PProd;

export const Environment: EnvironmentModel = {
  current: env,
  businessLine: BusinessLines.AssetServicing,

  application_id: 'assetservicing-demo',
  helpcenter_topic_id_documents: 'cat.yfpq1wvn',

  sgconnect_client_id: 'bf61f31f-cf2a-4a9b-9056-3f6dc351a4ba',
  sgconnect_scope: [
    'api.gallery-gfa-asset-servicing.v1',
    'api.sgssgreat-front.v1'
  ],
  sgconnect_acr: 'L3',

  document_domain: 'sgmarkets.com',
  piwik_site_id: '877541046',
  piwik_url: 'https://sg-analytics-uat.fr.world.socgen/piwik/',

  apparence_site_title: 'Asset servicing',
  apparence_limit_menu_elements: 15,

  func_strict401: false,

  host_legacy: 'https://fundadmin-demo-legacy.sgmarkets.com/',

  // nav
  host_legacy_nav: '',
  host_api_nav: '',

  // api
  host_api_legacy: 'https://fundadmin-demo-legacy.sgmarkets.com/AssetServ/api/',
  host_api_front: 'https://fundadmin-demo-api.sgmarkets.com/',
  host_api_great: 'https://fundadmin-demo-api.sgmarkets.com/reporting',

  // dashboard - no
  host_api_fundservices: '',
  host_cdn_widget: ''
};
