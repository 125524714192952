import { Component, DoCheck, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import * as moment from 'moment';
import { Environment } from '../../../environments/environment';

import { Subscription } from 'rxjs';
import { FundDetailsInputData, FundDetailsTab } from '../../models';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-fund-details',
  templateUrl: './fund-details.component.html',
  styleUrls: ['./fund-details.component.scss']
})
export class FundDetailsComponent implements OnInit, OnDestroy {

  public fundServicesApiUrl = Environment.host_api_fundservices;
  public fundDetailsTab = FundDetailsTab;
  public activeTab = FundDetailsTab.Synthesis;
  public inputData: FundDetailsInputData;
  public datePickerInputData: { lang: string }

  public fundCode: string;
  public navDate: string;
  public pocketCode: string;

  private routerEventsSubscription: Subscription;

  constructor(private router: Router, private globals: Globals) {
    this.routerEventsSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const data = this.router.getCurrentNavigation().extras.state.data;
        this.fundCode = data.fundCode;
        this.pocketCode = data.pocketCode;
        this.navDate = moment(data.navDate).format('YYYY-MM-DD');
      }
    });
  }

  ngOnInit() {
    this.inputData = {
      fundCode: '',
      pocketCode: '',
      keyNavSequence: -1,
      navDate: '',
      lang: this.globals.language$.value
    };
    this.datePickerInputData = {
      lang: this.globals.language$.value
    }
  }
  ngOnDestroy() {
    this.routerEventsSubscription.unsubscribe();
  }

  onClickSynthesis() {
    this.activeTab = FundDetailsTab.Synthesis;
  }

  onClickStatementOfHoldings() {
    this.activeTab = FundDetailsTab.StatemtentOfHoldings;
  }

  onClickTrades() {
    this.activeTab = FundDetailsTab.Trades;
  }

  onClickSubsReds() {
    this.activeTab = FundDetailsTab.SubsReds;
  }

  onClickCash() {
    this.activeTab = FundDetailsTab.Cash;
  }

  onClickForcedPrices() {
    this.activeTab = FundDetailsTab.ForcedPrices;
  }

  onClickCompliances() {
    this.activeTab = FundDetailsTab.Compliances;
  }

  onClickNavControl() {
    this.activeTab = FundDetailsTab.NavControl;
  }

  @HostListener('gca-fund-selector--value-changed', ['$event.detail'])
  onFundSelectorValueChanged(eventDetail) {
    //const inputData = this.inputData;
    const fund = JSON.parse(eventDetail.fundCode);
    // inputData.fundCode = fund.fundCode;
    // inputData.pocketCode = fund.pocketCode;
    // this.inputData = { ...inputData };

    this.inputData.fundCode = fund.fundCode;
    this.inputData.pocketCode = fund.pocketCode;
    this.fundCode = fund.fundCode;
  }

  @HostListener('gca-nav-date-selector--value-changed', ['$event.detail'])
  onNavDateSelectorValueChanged(eventDetail) {
    const inputData = this.inputData;
    const navDate = JSON.parse(eventDetail.navDate);
    inputData.navDate = navDate.navDate;
    inputData.keyNavSequence = navDate.keyNavSequence;
    this.inputData = { ...inputData };
  }

}
