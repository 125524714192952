<app-loading *ngIf="!appLoaded && !hasErrors"></app-loading>

<ng-template [ngIf]="appLoaded && !hasErrors">
  <app-navbar></app-navbar>

  <div class="flex-grow" appScriptsLoader>
    <router-outlet></router-outlet>
  </div>

  <sgwt-help-center
    id="sgwt-help-center"
    [attr.application-id]="applicationId"
    sg-connect-support="sg-connect-v2"
    default-send-to="service.gap-sgss@sgss.socgen.com">
  </sgwt-help-center>

  <sgwt-splash-screen id="my-splash-screen" [attr.application]="applicationId">
  </sgwt-splash-screen>

</ng-template>

<ng-template [ngIf]="hasError('401')">
  <!-- 401 -->
  <app-unauthorized></app-unauthorized>
</ng-template>

<ng-template [ngIf]="hasError('500')">
  <!-- 500 -->
  <app-servererror></app-servererror>
</ng-template>

  <sgwt-mini-footer
    #miniFooter
    id="fundadmin-footer"
    design="white"
    mode="sg-markets"
    container="container-fluid"
    contact-us="{
      'mail': 'service.gap-sgss@sgss.socgen.com'
    }"
    accessibility-compliance="none"
    legal-notices ="{'en':[{'label': 'Descriptions of services', 'value': 'javascript:fetchLegalDocumentEvent()', 'openInSamePage':true}], 
    'fr':[{'label': 'Descriptions de services', 'value': 'javascript:fetchLegalDocumentEvent()', 'openInSamePage':true}]}"
    cookies-consent
  >
  </sgwt-mini-footer>
