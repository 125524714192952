/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./lagacy-iframe-renderer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../pipes/safe-url.pipe";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "./lagacy-iframe-renderer.component";
var styles_LagacyIframeRendererComponent = [i0.styles];
var RenderType_LagacyIframeRendererComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LagacyIframeRendererComponent, data: {} });
export { RenderType_LagacyIframeRendererComponent as RenderType_LagacyIframeRendererComponent };
function View_LagacyIframeRendererComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "centered"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "lds-dual-ring"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["class", "sr-only"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(3, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["IFramLegacy.Loading"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 3, 0, currVal_0); }, null); }
function View_LagacyIframeRendererComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "iframe-container"]], [[4, "display", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["iframe", 1]], null, 1, "iframe", [["class", "ifrm-gallery"]], [[8, "src", 5]], [[null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (_co.load() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.loaded ? "block" : "none"); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.iframeUrl)); _ck(_v, 1, 0, currVal_1); }); }
export function View_LagacyIframeRendererComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.SafeUrlPipe, [i4.DomSanitizer]), i1.ɵqud(671088640, 1, { iframeRef: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LagacyIframeRendererComponent_1)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LagacyIframeRendererComponent_2)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loaded; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.iframeUrl; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_LagacyIframeRendererComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-lagacy-iframe-renderer", [], null, null, null, View_LagacyIframeRendererComponent_0, RenderType_LagacyIframeRendererComponent)), i1.ɵdid(1, 49152, null, 0, i6.LagacyIframeRendererComponent, [], null, null)], null, null); }
var LagacyIframeRendererComponentNgFactory = i1.ɵccf("app-lagacy-iframe-renderer", i6.LagacyIframeRendererComponent, View_LagacyIframeRendererComponent_Host_0, { iframeUrl: "iframeUrl" }, {}, []);
export { LagacyIframeRendererComponentNgFactory as LagacyIframeRendererComponentNgFactory };
