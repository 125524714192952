import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Environment } from './environments/environment';
import { ErrorCodes, ParameterKeys } from './models/constants';
import { User } from './models/user.model';

@Injectable({
    providedIn: 'root'
})
export class Globals {
    private _appLoaded = false;
    public appLoadedSource = new Subject<boolean>();
    appLoaded$ = this.appLoadedSource.asObservable();
    public errorSource = new Subject<ErrorCodes>();
    error$ = this.errorSource.asObservable();
    private environment = Environment;
    public languagesupport: string[] = [];
    public user: User;
    private _language: string;

    public language$: BehaviorSubject<string> = new BehaviorSubject('EN');

    public get language() {
        return this._language;
    }
    
    public set language(value: string) {
        this._language = value;
        this.language$.next(value);
    }
    
    public set appLoaded(value: boolean) {
        console.log('global apploaded: ' + value);
        this._appLoaded = value;
        this.appLoadedSource.next(value);
    }

    public get appLoaded() {
        return this._appLoaded;
    }

    public emitError(code: ErrorCodes) {
        this.appLoaded = false;
        this.errorSource.next(code || ErrorCodes.Undefined);
    }

    public get Environment() {
        return this.environment;
    }

    public EnvParam(key: ParameterKeys) {
        return this.environment[key];
    }
}
