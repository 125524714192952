/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./fund-details-synthesis.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./fund-details-synthesis.component";
var styles_FundDetailsSynthesisComponent = [i0.styles];
var RenderType_FundDetailsSynthesisComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FundDetailsSynthesisComponent, data: {} });
export { RenderType_FundDetailsSynthesisComponent as RenderType_FundDetailsSynthesisComponent };
export function View_FundDetailsSynthesisComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "row p-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "gca-fund-details-summary", [["class", "col-3 mb-3"], ["style", "height:410px;"]], [[8, "apiUrl", 0], [8, "inputData", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "gca-fund-shareclasses-list", [["class", "col-9 mb-3"], ["style", "height:410px;"]], [[8, "apiUrl", 0], [8, "inputData", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "gca-fund-breakdown-asset-type-chart", [["class", "col-3 mt-2"], ["style", "height:410px;"]], [[8, "apiUrl", 0], [8, "inputData", 0]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "gca-management-fees-list", [["class", "col-6 mt-2"], ["style", "height:410px;"]], [[8, "apiUrl", 0], [8, "inputData", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "gca-compliance-breaches-fund-list", [["class", "col-3 mt-2"], ["style", "height:410px;"]], [[8, "apiUrl", 0], [8, "inputData", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fundServicesApiUrl; var currVal_1 = _co.inputData; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.fundServicesApiUrl; var currVal_3 = _co.inputData; _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_4 = _co.fundServicesApiUrl; var currVal_5 = _co.inputData; _ck(_v, 3, 0, currVal_4, currVal_5); var currVal_6 = _co.fundServicesApiUrl; var currVal_7 = _co.inputData; _ck(_v, 4, 0, currVal_6, currVal_7); var currVal_8 = _co.fundServicesApiUrl; var currVal_9 = _co.inputData; _ck(_v, 5, 0, currVal_8, currVal_9); }); }
export function View_FundDetailsSynthesisComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-fund-details-synthesis", [], null, null, null, View_FundDetailsSynthesisComponent_0, RenderType_FundDetailsSynthesisComponent)), i1.ɵdid(1, 49152, null, 0, i2.FundDetailsSynthesisComponent, [], null, null)], null, null); }
var FundDetailsSynthesisComponentNgFactory = i1.ɵccf("app-fund-details-synthesis", i2.FundDetailsSynthesisComponent, View_FundDetailsSynthesisComponent_Host_0, { inputData: "inputData" }, {}, []);
export { FundDetailsSynthesisComponentNgFactory as FundDetailsSynthesisComponentNgFactory };
