<div [className]="className" style="position:absolute;top:40px;right:20px" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header">
      <strong>{{title}}</strong>
      <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close" (click)="onClickClose()">
        <i class="icon">close</i>
      </button>
    </div>
    <div class="toast-body">
      {{message}}
    </div>
</div>