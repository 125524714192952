import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { IChangeEvent } from 'sgwt-account-center';

import { IMenuItem } from '../../../models';

import { FundAdminCultureService,
         FundAdminIframeService,
         FundAdminMenuService,
         FundAdminRoutingService } from 'src/app/core/services';
import { Globals } from 'src/app/globals';
import { ParameterKeys } from 'src/app/models/constants';
import { additionalI18n } from 'src/assets/i18n';
import { JQUERY_TOKEN } from '../../../injection-tokens';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements AfterViewInit, OnDestroy, OnInit {

  @ViewChild('sgwtAccountCenter', { static: false }) sgwtAccountCenter: ElementRef;

  currentLanguage: string;
  menu: IMenuItem[];
  showDashboard: boolean;

  i18n: any;

  constructor(
    private globals: Globals,
    @Inject(JQUERY_TOKEN) private jquery: any,
    private renderer: Renderer2,
    public translate: TranslateService,
    private router: Router,
    private fundAdminMenuService: FundAdminMenuService,
    private fundAdminCultureService: FundAdminCultureService,
    private fundAdminRoutingService: FundAdminRoutingService,
    private fundAdminIframeService: FundAdminIframeService
  ) {

    this.menu = this.fundAdminMenuService.getMenu();
    this.i18n = additionalI18n;

    this.showDashboard = false;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.renderer.setProperty(this.sgwtAccountCenter.nativeElement, 'language', this.globals.language);

    this.sgwtAccountCenter.nativeElement.addEventListener(
      'sgwt-account-center--language-changed',
      this.onLanguageChanged
    );
  }

  ngOnDestroy() {
    this.sgwtAccountCenter.nativeElement.removeEventListener(
      'sgwt-account-center--language-changed',
      this.onLanguageChanged
    );
  }

  onLanguageChanged = async (evt: IChangeEvent) => {
    const currentLang = evt.detail.language.toLowerCase();

    const isoCulture = this.fundAdminCultureService.setCulture(currentLang);
    this.translate.setDefaultLang(isoCulture);
    this.translate.use(isoCulture);

    // pass language to single-spa
    (window as any).lang = currentLang;

    const legacyUrl = this.fundAdminMenuService.getLegacyUrl(this.router.url);
    this.fundAdminRoutingService.popRoutes(this.menu);

    await this.fundAdminIframeService.changeLanguage(currentLang);

    const menu = await this.fundAdminMenuService.buildMenu(isoCulture);
    this.fundAdminRoutingService.pushRoutes(menu);
    this.menu = menu;

    if (!legacyUrl) {
      this.router.navigateByUrl('/');
    }
    const newRouteUrl = this.fundAdminMenuService.getRouteUrl(legacyUrl);
    if (!newRouteUrl) {
      this.router.navigateByUrl('/');
    }
    this.globals.language = currentLang;
    this.router.navigateByUrl(newRouteUrl);
  }

  get serviceName() {
    return this.globals.EnvParam(ParameterKeys.APPARENCE_SITE_TITLE);
  }

  onClickNavbarLink() {
    const navbarDisplay = this.jquery('#navbar-toggler').css('display');
    if (navbarDisplay === 'block') {
      this.jquery('#navbar').collapse('hide');
    }
  }

}
