import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { FundAdminCultureService, FundAdminMenuService } from 'src/app/core/services';

import { Globals } from 'src/app/globals';
import { IMenuItem } from 'src/app/models';
import { BusinessLines, ParameterKeys } from 'src/app/models/constants';
import { additionalI18n } from 'src/assets/i18n';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})
export class SplashComponent implements OnInit, OnDestroy {

  items: any;

  businessLineTitle: string;
  businessLineDesc: string;

  i18n: any;

  constructor(
    private globals: Globals,
    public translate: TranslateService,
    private fundAdminMenuService: FundAdminMenuService,
    private fundAdminCultureService: FundAdminCultureService
  ) {
    this.i18n = additionalI18n;

    this.setBusinessLineResource(this.globals.Environment.businessLine);

    this.fundAdminMenuService.menuLoaded$.subscribe((res: any) => {
      this.items = this.convertItemToBlockMenu(this.fundAdminMenuService.menu);
    });

    this.fundAdminCultureService.culture$.subscribe((isoCulture: string) => {
      this.translate.use(isoCulture);
    });
  }

  ngOnInit() {
    this.items = this.convertItemToBlockMenu(this.fundAdminMenuService.menu);
  }

  ngOnDestroy(): void {
  }

  get IsFundAdm(): boolean {
    return this.globals.Environment.businessLine === BusinessLines.FundAdministration;
  }

  private setBusinessLineResource(businessLine: BusinessLines) {
    const defaultServiceTitle = this.globals.EnvParam(ParameterKeys.APPARENCE_SITE_TITLE) as string;
    switch (businessLine) {
      case BusinessLines.AssetServicing:
        this.businessLineTitle = defaultServiceTitle || 'Splash_BusinessLine_AssetServicing';
        this.businessLineDesc = 'Splash_BusinessLineDesc_AssetServicing';
        break;
      case BusinessLines.FundAdministration:
        this.businessLineTitle = defaultServiceTitle || 'Splash_BusinessLine_FundAdministration';
        this.businessLineDesc = 'Splash_BusinessLineDesc_FundAdministration';
        break;
      case BusinessLines.SecuritiesLending:
        this.businessLineTitle = defaultServiceTitle || 'Splash_BusinessLine_SecuritiesLending';
        this.businessLineDesc = 'Splash_BusinessLineDesc_SecuritiesLending';
        break;
    }
  }
  private convertItemToBlockMenu(items: IMenuItem[]): any[] {
    // 1st level
    const res = items.map((item: IMenuItem) => {
      // 2nd level
      return item.children.map((subItem: IMenuItem) => {
        // 3rd level
        const hasChildren = subItem.children && subItem.children.length > 0;
        return {
          style: {
            size : (
              subItem.children.length > 16 ? 'l' : subItem.children.length > 8 ? 'm' : 's'
            ) // s m l => l9
          },
          groupTitle: item.label,
          groupLenth: item.children.length,
          subItemTitle: subItem.label,
          subItemLength: subItem.children.length,
          children: hasChildren ? subItem.children : subItem,
          childrenLength: subItem.children.length,
          hasChildren
        };
      });
    });
    const flatres = (res as any).flatMap((i: any) => i);
    const flatresSize = (flatres as any[]).length;

    // XXL readapt
    const placeholder = 3;
    let currentLigne = 0 + placeholder;
    for (let i = 0; i < flatresSize ; i++) {
      const currrentSize = flatres[i].style.size;
      const currrentPlace = currrentSize === 'l' ? 12 : currrentSize === 'm' ? 6 : 3;
      if (currentLigne === 3 && currrentPlace === 12) {
        currentLigne = 0;
        flatres[i].style.size = 'l9';
        continue;
      }

      const tempCurrentLign = currentLigne + currrentPlace;
      currentLigne = tempCurrentLign === 12 ? 0 : tempCurrentLign > 12 ? currrentPlace : tempCurrentLign;
    }

    return flatres;
  }

  // get isBLFundAdministration() {
  //     return this.globals.Environment.businessLine === BusinessLines.FundAdministration;
  // }

  // get isBLAssetServicing() {
  //     return this.globals.Environment.businessLine === BusinessLines.AssetServicing;
  // }

  // get isBLSecuritiesLending() {
  //     return this.globals.Environment.businessLine === BusinessLines.SecuritiesLending;
  // }

  // get isCustody() {
  //     return this.globals.Environment.businessLine === BusinessLines.Custody;
  // }

  // get isCustodyInternational() {
  //     return this.globals.Environment.businessLine === BusinessLines.CustodyInternational;
  // }
}
