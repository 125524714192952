import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { NavInfo } from 'src/app/shared/models';
import { Environment } from 'src/app/environments/environment';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class FundAdminNavService {

  constructor(
    private httpClient: HttpClient
  ) { }

  public getNavs(): Observable<NavInfo[]> {
    const host = Environment.host_api_fundservices;
    const url = `${host}navs`;

    const headers = new HttpHeaders({});

    return this.httpClient.get<any>(url, { headers })
        .pipe(map(result=>result.data));
  }
}
