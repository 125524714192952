import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { LoDashStatic } from 'lodash';
import { Globals } from 'src/app/globals';
import { LODASH_TOKEN } from 'src/app/injection-tokens';
import { IMenuItem, MenuItem, MenuItemType } from 'src/app/models';
import { ParameterKeys } from 'src/app/models/constants';
import { FundAdminCultureService } from '../gallery-sgmarkets-gfa-culture/fund-admin-culture.service';

@Injectable({
  providedIn: 'root'
})
export class FundAdminMenuService {
  private menuLoadedSource = new Subject<IMenuItem[]>();
  menuLoaded$ = this.menuLoadedSource.asObservable();

  public menu: IMenuItem[] = [];
  private routes: Array<[string, string, MenuItemType]> = [];

  constructor(
    private httpClient: HttpClient,
    private globals: Globals,
    private appCultureService: FundAdminCultureService,
    @Inject(LODASH_TOKEN) private lodash: LoDashStatic
  ) {
  }

  // get user menu via front api
  private getMenuJSON(lang: number): Promise<any[]> {
    const host = this.globals.EnvParam(ParameterKeys.HOST_API_LEGACY);
    const url = `${host}v1/user/current/menu`;

    const headers = new HttpHeaders({});

    return this.httpClient.get<any[]>(url, { headers }).toPromise();
  }

  // build menu & convert , lang default as en
  public async buildMenu(isoCulture: string) {
    const lang: number = this.appCultureService.convertToIdCulture(isoCulture);
    const getMenuResult = await this.getMenuJSON(lang);
    const menuItems = getMenuResult as IMenuItem[] ;
    this.routes = [];
    const apparenceLimitMenuElements = this.globals.EnvParam(ParameterKeys.APPARENCE_LIMIT_MENU_ELEMENTS) as number;
    const limitMenu = menuItems.slice(0, apparenceLimitMenuElements);

    this.menu = this.buildIMenuItems(limitMenu);
    this.menuLoadedSource.next(this.menu);
    return Promise.resolve(this.menu);
  }

  // getter menu
  public getMenu() {
    return this.menu;
  }

  get hasDashboard() {
    return true;
  }

  // build menu ( path / rewriteurl / type ) for router
  private buildIMenuItems(menu: any[], tracks: string[] = []): IMenuItem[] {
    const separator = '/';
    const menuItems = [];
    let idx = 0;

    for (const item of menu) {
      if (item) {
        const trackLabel = this.lodash.kebabCase(item.label.toLowerCase());
        const track = tracks.length === 0 ? [trackLabel] : [...tracks, trackLabel];
        const cleanedUrlType = item.url ? this.getCleanedUrlAndType(item.url) : null;
        const cleanedUrl = cleanedUrlType ? cleanedUrlType[0] : '';
        const urlType = cleanedUrlType ? cleanedUrlType[1] : MenuItemType.Legacy;

        // init current menu states
        const currentMenu = new MenuItem(item.label, cleanedUrl, track, urlType);
        currentMenu.rewritedUrl = cleanedUrl && urlType === MenuItemType.Legacy  ? [...track].join(separator) : cleanedUrl;

        if (item.children && item.children.length > 0) {
          currentMenu.children = this.buildIMenuItems(item.children, currentMenu.tracks);
          currentMenu.url = null;
          currentMenu.rewritedUrl = null;
        }

        idx++;

        // router builder
        menuItems.push(currentMenu as MenuItem);
        if (currentMenu.url) {
          this.routes.push([currentMenu.url, currentMenu.rewritedUrl, currentMenu.type]);
        }

      }
    }
    return menuItems;
  }

  public getLegacyUrl(rewritedUrl: string) {
    // console.log(`#####${rewritedUrl}`);
    if (rewritedUrl.indexOf('/') === 0) {
      rewritedUrl = rewritedUrl.substr(1);
    }
    const item = this.routes.find(it => it[1] === rewritedUrl);
    // console.log(this.routes);

    return item && item[2] === MenuItemType.Legacy ? item[0] : '';
  }

  public getRouteUrl(legacyUrl: string) {
    const item = this.routes.find(it => it[0] === legacyUrl);

    return item ? item[1] : '';
  }

  private getCleanedUrlAndType(url: string): [string, MenuItemType] {
    // Legacy
    const regexLegacy = /(\/\w+\/)(index\.aspx)\?(m=\w+)&(p=\w+)/g;
    if (url.match(regexLegacy)) {
      const legacyUrl = `${this.globals.EnvParam(ParameterKeys.HOST_LEGACY)}${url}`;
      return [legacyUrl, MenuItemType.Legacy];
    }

    // Legacy Angular Nav
    const regexLegacyAngularNav = /(\/\w+\/)(app\/index\.html)#/g;
    if (url.match(regexLegacyAngularNav)) {
      const legacyNavUrl = `${this.globals.EnvParam(ParameterKeys.HOST_LEGACY_NAV)}`;
      return [legacyNavUrl, MenuItemType.Legacy];
    }

    // Exception URL Angular
    const regexComposantAngular = [
      { regex: /(\S+)(reporting\/pere)/, idx : 2 } ,
      { regex: /(\S+)(FundAdm\/)?(dashboard)/, idx : 3 },
      { regex: /(\S+)(FundAdm\/)?(fund-details)/, idx : 3 },
      { regex: /(\S+)(FundAdm\/)?(funds-factsheet)/, idx : 3 }
    ];
    for (const regexComposant of regexComposantAngular) {
      const found = url.match(regexComposant.regex);
      if (found) {
        const ComposantUrl = `${found[regexComposant.idx]}`;
        return [ComposantUrl, MenuItemType.Angular];
      }
    }
    // By default for Angular
    return [url, MenuItemType.Angular];
  }
}
