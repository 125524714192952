var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { RouteReuseStrategy } from '@angular/router';
var MicroFrontendRouteReuseStrategy = /** @class */ (function (_super) {
    __extends(MicroFrontendRouteReuseStrategy, _super);
    function MicroFrontendRouteReuseStrategy() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MicroFrontendRouteReuseStrategy.prototype.shouldDetach = function () {
        //   
        return false;
    };
    MicroFrontendRouteReuseStrategy.prototype.store = function () { };
    MicroFrontendRouteReuseStrategy.prototype.shouldAttach = function () {
        return false;
    };
    //   
    MicroFrontendRouteReuseStrategy.prototype.retrieve = function () {
        return null;
    };
    MicroFrontendRouteReuseStrategy.prototype.shouldReuseRoute = function (future, curr) {
        return future.routeConfig === curr.routeConfig || (future.data.app && (future.data.app === curr.data.app));
    };
    return MicroFrontendRouteReuseStrategy;
}(RouteReuseStrategy));
export { MicroFrontendRouteReuseStrategy };
