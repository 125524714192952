import { OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FundAdminCultureService, FundAdminMenuService } from 'src/app/core/services';
import { Globals } from 'src/app/globals';
import { BusinessLines } from 'src/app/models/constants';
import { additionalI18n } from 'src/assets/i18n';
var SplashComponent = /** @class */ (function () {
    function SplashComponent(globals, translate, fundAdminMenuService, fundAdminCultureService) {
        var _this = this;
        this.globals = globals;
        this.translate = translate;
        this.fundAdminMenuService = fundAdminMenuService;
        this.fundAdminCultureService = fundAdminCultureService;
        this.i18n = additionalI18n;
        this.setBusinessLineResource(this.globals.Environment.businessLine);
        this.fundAdminMenuService.menuLoaded$.subscribe(function (res) {
            _this.items = _this.convertItemToBlockMenu(_this.fundAdminMenuService.menu);
        });
        this.fundAdminCultureService.culture$.subscribe(function (isoCulture) {
            _this.translate.use(isoCulture);
        });
    }
    SplashComponent.prototype.ngOnInit = function () {
        this.items = this.convertItemToBlockMenu(this.fundAdminMenuService.menu);
    };
    SplashComponent.prototype.ngOnDestroy = function () {
    };
    Object.defineProperty(SplashComponent.prototype, "IsFundAdm", {
        get: function () {
            return this.globals.Environment.businessLine === BusinessLines.FundAdministration;
        },
        enumerable: true,
        configurable: true
    });
    SplashComponent.prototype.setBusinessLineResource = function (businessLine) {
        var defaultServiceTitle = this.globals.EnvParam("apparence_site_title" /* APPARENCE_SITE_TITLE */);
        switch (businessLine) {
            case BusinessLines.AssetServicing:
                this.businessLineTitle = defaultServiceTitle || 'Splash_BusinessLine_AssetServicing';
                this.businessLineDesc = 'Splash_BusinessLineDesc_AssetServicing';
                break;
            case BusinessLines.FundAdministration:
                this.businessLineTitle = defaultServiceTitle || 'Splash_BusinessLine_FundAdministration';
                this.businessLineDesc = 'Splash_BusinessLineDesc_FundAdministration';
                break;
            case BusinessLines.SecuritiesLending:
                this.businessLineTitle = defaultServiceTitle || 'Splash_BusinessLine_SecuritiesLending';
                this.businessLineDesc = 'Splash_BusinessLineDesc_SecuritiesLending';
                break;
        }
    };
    SplashComponent.prototype.convertItemToBlockMenu = function (items) {
        // 1st level
        var res = items.map(function (item) {
            // 2nd level
            return item.children.map(function (subItem) {
                // 3rd level
                var hasChildren = subItem.children && subItem.children.length > 0;
                return {
                    style: {
                        size: (subItem.children.length > 16 ? 'l' : subItem.children.length > 8 ? 'm' : 's') // s m l => l9
                    },
                    groupTitle: item.label,
                    groupLenth: item.children.length,
                    subItemTitle: subItem.label,
                    subItemLength: subItem.children.length,
                    children: hasChildren ? subItem.children : subItem,
                    childrenLength: subItem.children.length,
                    hasChildren: hasChildren
                };
            });
        });
        var flatres = res.flatMap(function (i) { return i; });
        var flatresSize = flatres.length;
        // XXL readapt
        var placeholder = 3;
        var currentLigne = 0 + placeholder;
        for (var i = 0; i < flatresSize; i++) {
            var currrentSize = flatres[i].style.size;
            var currrentPlace = currrentSize === 'l' ? 12 : currrentSize === 'm' ? 6 : 3;
            if (currentLigne === 3 && currrentPlace === 12) {
                currentLigne = 0;
                flatres[i].style.size = 'l9';
                continue;
            }
            var tempCurrentLign = currentLigne + currrentPlace;
            currentLigne = tempCurrentLign === 12 ? 0 : tempCurrentLign > 12 ? currrentPlace : tempCurrentLign;
        }
        return flatres;
    };
    return SplashComponent;
}());
export { SplashComponent };
