import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { FundAdminRouteGuard } from './core/guards';
import { Routes_Paths } from './models/constants/RoutePaths';
import {
  DashboardComponent,
  FundDetailsComponent,
  IframePageComponent,
  SplashComponent
} from './shared/components';
import { ReportingPereComponent } from './shared/components/reporting-pere/reporting-pere.component';

export const ROUTES: Routes = [
  {
    path: Routes_Paths.Mail,
    component: IframePageComponent,
    canActivate: [FundAdminRouteGuard]
  },
  {
    path: Routes_Paths.ReportingPere,
    component: ReportingPereComponent,
    canActivate: [FundAdminRouteGuard]
  },
  {
    path: Routes_Paths.Homepage,
    component: SplashComponent,
    canActivate: [FundAdminRouteGuard]
  },
  {
    path: Routes_Paths.Dashboard,
    component: DashboardComponent,
    canActivate: [FundAdminRouteGuard]
  },
  {
    path: Routes_Paths.FundDetails,
    component: FundDetailsComponent,
    canActivate: [FundAdminRouteGuard]
  },
  {
    path: Routes_Paths.FundsFactsheet,
    children: [
      {
        path: '**',
        loadChildren: () =>
          import('./spa-host/spa-host.module').then((m) => m.SpaHostModule),
        data: { app: '@funds-factsheet' }
      }
    ]
  },
  { path: '**', component: SplashComponent, canActivate: [FundAdminRouteGuard] }
];

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRootingModule {}
