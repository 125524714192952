/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./fund-details-statement-of-holdings.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./fund-details-statement-of-holdings.component";
var styles_FundDetailsStatementOfHoldingsComponent = [i0.styles];
var RenderType_FundDetailsStatementOfHoldingsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FundDetailsStatementOfHoldingsComponent, data: {} });
export { RenderType_FundDetailsStatementOfHoldingsComponent as RenderType_FundDetailsStatementOfHoldingsComponent };
export function View_FundDetailsStatementOfHoldingsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "funddetails-widgets row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "funddetails-column col-xl-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "gca-statement-of-holdings-grid", [["class", "funddetails-widget-100 mb-4 mt-4"]], [[8, "apiUrl", 0], [8, "inputData", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fundServicesApiUrl; var currVal_1 = _co.inputData; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_FundDetailsStatementOfHoldingsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-fund-details-statement-of-holdings", [], null, null, null, View_FundDetailsStatementOfHoldingsComponent_0, RenderType_FundDetailsStatementOfHoldingsComponent)), i1.ɵdid(1, 638976, null, 0, i2.FundDetailsStatementOfHoldingsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FundDetailsStatementOfHoldingsComponentNgFactory = i1.ɵccf("app-fund-details-statement-of-holdings", i2.FundDetailsStatementOfHoldingsComponent, View_FundDetailsStatementOfHoldingsComponent_Host_0, { inputData: "inputData" }, {}, []);
export { FundDetailsStatementOfHoldingsComponentNgFactory as FundDetailsStatementOfHoldingsComponentNgFactory };
