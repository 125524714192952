<div class="server-error d-flex flex-column" style="height:100vh;">
  <section class="section-1">
      <div class="container-fluid d-block d-lg-none px-4">
      <div class="row">
          <div class="col-7 bg-white">
          <a href="#" class="d-inline-block">
              <img class="my-4" src="../../../assets/logo.svg" height="32">
          </a>
          </div>
          <div class="col-4 bg-white"></div>
      </div>
      </div>
      <div class="container d-none d-lg-block">
      <div class="row">
          <div class="col-7 bg-white">
          <a href="#" class="d-inline-block">
              <img class="spacing-mt-3 spacing-mb-5" src="../../../assets/logo_full.svg" height="32">
          </a>
          </div>
          <div class="col-4 bg-white"></div>
      </div>
      </div>
  </section>
  <section class="section-2">
      <div class="container-fluid">
      <div class="container main-wrapper">
          <div class="row">
          <div class="col-lg-2 bg-white pt-5 pb-4 py-lg-0">
  
              <span class="d-block d-md-none text-outline-socgen text-primary-alt font-weight-medium" style="font-size: 56px; font-family: 'Neue Haas Grotesk W04 Disp'">500</span>
  
  
              <span class="d-none d-md-block d-lg-none text-outline-socgen text-primary-alt font-weight-medium" style="line-height: 1.2; font-size: 56px; font-family: 'Neue Haas Grotesk W04 Disp'">500</span>
  
  
              <span class="mt-n2 d-none d-lg-inline-block d-xl-none display-2 text-outline-socgen text-primary-alt font-weight-medium">500</span>
  
  
              <span class="mt-n3 d-none d-xl-block display-1 text-outline-socgen text-primary-alt font-weight-medium" style="font-size: 96px; overflow: hidden">500</span>
          </div>
          <div class="col-lg-5  bg-white d-flex flex-column justify-content-between">
              <div>
              <h2 class="d-none d-lg-block display-2 font-weight-medium line-height-1 pt-0 pb-3">Something's wrong.</h2>
              <h1 class="d-lg-none display-3 font-weight-medium line-height-1 py-2">We're unable to complete your request at the moment.</h1>
  
              <!-- MOBILE -->
              <p class="d-lg-none mb-5 h3 font-weight-medium text-secondary line-height-1">We're unable to complete your request at the moment.</p>
  
              <!-- DESKTOP -->
              <p class="d-none d-lg-block d-xxl-none display-3 font-weight-medium text-secondary w-75 line-height-1">
                  We're unable to complete your request at the moment.
              </p>
  
              <!-- XXL -->
              <p class="d-none d-xxl-block display-3 font-weight-medium text-secondary w-75 line-height-1">
                  We're unable to complete your request at the moment.
              </p>
  
              </div>
              <div class="mb-5 d-none d-md-flex">
              <button class="btn btn-xl btn-primary" onclick="window.location.href='https\://www.sgmarkets.com';">Go Back</button>
              <a class="btn btn-xl btn-flat-secondary ml-1" href="mailto:service.gap-sgss@sgss.socgen.com">Contact us</a>
              </div>
              <div class="d-block d-md-none">
              <button class="btn btn-xl btn-primary btn-block mb-1" onclick="window.location.href='https\://www.sgmarkets.com';">Go Back</button>
              <a class="btn btn-xl btn-flat-secondary ml-1 btn-block" href="mailto:service.gap-sgss@sgss.socgen.com">Contact us</a> 
              </div>
          </div>
          <div class="col-4 px-0 position-relative">
              <div class="d-none d-lg-block" style="padding-top: 100%; background: #fff url(../../../assets/errorpage/500.svg) center no-repeat; background-size: 130%"></div>
          </div>
          </div>
      </div>
      </div> 
  </section>
  <section class="section-3 flex-grow-1 d-flex">
      <div class="container-fluid">
          <div class="container h-100 d-none d-lg-block">
          <div class="row h-100">
              <div class="offset-2 col-5 pt-5 text-muted" style="background-color: #fafafa">
              © Societe Generale Group 2019
              </div>
          </div>
          </div>
          <footer class="container-fluid d-lg-none position-absolute text-muted" style="bottom: 0; left: 0; right: 0; background-color: #fafafa">
          <div class="container py-4">
              © Societe Generale Group 2019
          </div>
          </footer>
      </div>
  </section>
      
</div>