var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Globals } from 'src/app/globals';
import { MenuItem, MenuItemType } from 'src/app/models';
import { FundAdminCultureService } from '../gallery-sgmarkets-gfa-culture/fund-admin-culture.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../globals";
import * as i3 from "../gallery-sgmarkets-gfa-culture/fund-admin-culture.service";
import * as i4 from "../../../injection-tokens";
var FundAdminMenuService = /** @class */ (function () {
    function FundAdminMenuService(httpClient, globals, appCultureService, lodash) {
        this.httpClient = httpClient;
        this.globals = globals;
        this.appCultureService = appCultureService;
        this.lodash = lodash;
        this.menuLoadedSource = new Subject();
        this.menuLoaded$ = this.menuLoadedSource.asObservable();
        this.menu = [];
        this.routes = [];
    }
    // get user menu via front api
    FundAdminMenuService.prototype.getMenuJSON = function (lang) {
        var host = this.globals.EnvParam("host_api_legacy" /* HOST_API_LEGACY */);
        var url = host + "v1/user/current/menu";
        var headers = new HttpHeaders({});
        return this.httpClient.get(url, { headers: headers }).toPromise();
    };
    // build menu & convert , lang default as en
    FundAdminMenuService.prototype.buildMenu = function (isoCulture) {
        return __awaiter(this, void 0, void 0, function () {
            var lang, getMenuResult, menuItems, apparenceLimitMenuElements, limitMenu;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        lang = this.appCultureService.convertToIdCulture(isoCulture);
                        return [4 /*yield*/, this.getMenuJSON(lang)];
                    case 1:
                        getMenuResult = _a.sent();
                        menuItems = getMenuResult;
                        this.routes = [];
                        apparenceLimitMenuElements = this.globals.EnvParam("apparence_limit_menu_elements" /* APPARENCE_LIMIT_MENU_ELEMENTS */);
                        limitMenu = menuItems.slice(0, apparenceLimitMenuElements);
                        this.menu = this.buildIMenuItems(limitMenu);
                        this.menuLoadedSource.next(this.menu);
                        return [2 /*return*/, Promise.resolve(this.menu)];
                }
            });
        });
    };
    // getter menu
    FundAdminMenuService.prototype.getMenu = function () {
        return this.menu;
    };
    Object.defineProperty(FundAdminMenuService.prototype, "hasDashboard", {
        get: function () {
            return true;
        },
        enumerable: true,
        configurable: true
    });
    // build menu ( path / rewriteurl / type ) for router
    FundAdminMenuService.prototype.buildIMenuItems = function (menu, tracks) {
        var e_1, _a;
        if (tracks === void 0) { tracks = []; }
        var separator = '/';
        var menuItems = [];
        var idx = 0;
        try {
            for (var menu_1 = __values(menu), menu_1_1 = menu_1.next(); !menu_1_1.done; menu_1_1 = menu_1.next()) {
                var item = menu_1_1.value;
                if (item) {
                    var trackLabel = this.lodash.kebabCase(item.label.toLowerCase());
                    var track = tracks.length === 0 ? [trackLabel] : __spread(tracks, [trackLabel]);
                    var cleanedUrlType = item.url ? this.getCleanedUrlAndType(item.url) : null;
                    var cleanedUrl = cleanedUrlType ? cleanedUrlType[0] : '';
                    var urlType = cleanedUrlType ? cleanedUrlType[1] : MenuItemType.Legacy;
                    // init current menu states
                    var currentMenu = new MenuItem(item.label, cleanedUrl, track, urlType);
                    currentMenu.rewritedUrl = cleanedUrl && urlType === MenuItemType.Legacy ? __spread(track).join(separator) : cleanedUrl;
                    if (item.children && item.children.length > 0) {
                        currentMenu.children = this.buildIMenuItems(item.children, currentMenu.tracks);
                        currentMenu.url = null;
                        currentMenu.rewritedUrl = null;
                    }
                    idx++;
                    // router builder
                    menuItems.push(currentMenu);
                    if (currentMenu.url) {
                        this.routes.push([currentMenu.url, currentMenu.rewritedUrl, currentMenu.type]);
                    }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (menu_1_1 && !menu_1_1.done && (_a = menu_1.return)) _a.call(menu_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return menuItems;
    };
    FundAdminMenuService.prototype.getLegacyUrl = function (rewritedUrl) {
        // console.log(`#####${rewritedUrl}`);
        if (rewritedUrl.indexOf('/') === 0) {
            rewritedUrl = rewritedUrl.substr(1);
        }
        var item = this.routes.find(function (it) { return it[1] === rewritedUrl; });
        // console.log(this.routes);
        return item && item[2] === MenuItemType.Legacy ? item[0] : '';
    };
    FundAdminMenuService.prototype.getRouteUrl = function (legacyUrl) {
        var item = this.routes.find(function (it) { return it[0] === legacyUrl; });
        return item ? item[1] : '';
    };
    FundAdminMenuService.prototype.getCleanedUrlAndType = function (url) {
        var e_2, _a;
        // Legacy
        var regexLegacy = /(\/\w+\/)(index\.aspx)\?(m=\w+)&(p=\w+)/g;
        if (url.match(regexLegacy)) {
            var legacyUrl = "" + this.globals.EnvParam("host_legacy" /* HOST_LEGACY */) + url;
            return [legacyUrl, MenuItemType.Legacy];
        }
        // Legacy Angular Nav
        var regexLegacyAngularNav = /(\/\w+\/)(app\/index\.html)#/g;
        if (url.match(regexLegacyAngularNav)) {
            var legacyNavUrl = "" + this.globals.EnvParam("host_legacy_nav" /* HOST_LEGACY_NAV */);
            return [legacyNavUrl, MenuItemType.Legacy];
        }
        // Exception URL Angular
        var regexComposantAngular = [
            { regex: /(\S+)(reporting\/pere)/, idx: 2 },
            { regex: /(\S+)(FundAdm\/)?(dashboard)/, idx: 3 },
            { regex: /(\S+)(FundAdm\/)?(fund-details)/, idx: 3 },
            { regex: /(\S+)(FundAdm\/)?(funds-factsheet)/, idx: 3 }
        ];
        try {
            for (var regexComposantAngular_1 = __values(regexComposantAngular), regexComposantAngular_1_1 = regexComposantAngular_1.next(); !regexComposantAngular_1_1.done; regexComposantAngular_1_1 = regexComposantAngular_1.next()) {
                var regexComposant = regexComposantAngular_1_1.value;
                var found = url.match(regexComposant.regex);
                if (found) {
                    var ComposantUrl = "" + found[regexComposant.idx];
                    return [ComposantUrl, MenuItemType.Angular];
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (regexComposantAngular_1_1 && !regexComposantAngular_1_1.done && (_a = regexComposantAngular_1.return)) _a.call(regexComposantAngular_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        // By default for Angular
        return [url, MenuItemType.Angular];
    };
    FundAdminMenuService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FundAdminMenuService_Factory() { return new FundAdminMenuService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Globals), i0.ɵɵinject(i3.FundAdminCultureService), i0.ɵɵinject(i4.LODASH_TOKEN)); }, token: FundAdminMenuService, providedIn: "root" });
    return FundAdminMenuService;
}());
export { FundAdminMenuService };
