var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AfterViewInit, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FundAdminCultureService, FundAdminIframeService, FundAdminMenuService, FundAdminRoutingService } from 'src/app/core/services';
import { Globals } from 'src/app/globals';
import { additionalI18n } from 'src/assets/i18n';
var NavbarComponent = /** @class */ (function () {
    function NavbarComponent(globals, jquery, renderer, translate, router, fundAdminMenuService, fundAdminCultureService, fundAdminRoutingService, fundAdminIframeService) {
        var _this = this;
        this.globals = globals;
        this.jquery = jquery;
        this.renderer = renderer;
        this.translate = translate;
        this.router = router;
        this.fundAdminMenuService = fundAdminMenuService;
        this.fundAdminCultureService = fundAdminCultureService;
        this.fundAdminRoutingService = fundAdminRoutingService;
        this.fundAdminIframeService = fundAdminIframeService;
        this.onLanguageChanged = function (evt) { return __awaiter(_this, void 0, void 0, function () {
            var currentLang, isoCulture, legacyUrl, menu, newRouteUrl;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        currentLang = evt.detail.language.toLowerCase();
                        isoCulture = this.fundAdminCultureService.setCulture(currentLang);
                        this.translate.setDefaultLang(isoCulture);
                        this.translate.use(isoCulture);
                        // pass language to single-spa
                        window.lang = currentLang;
                        legacyUrl = this.fundAdminMenuService.getLegacyUrl(this.router.url);
                        this.fundAdminRoutingService.popRoutes(this.menu);
                        return [4 /*yield*/, this.fundAdminIframeService.changeLanguage(currentLang)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.fundAdminMenuService.buildMenu(isoCulture)];
                    case 2:
                        menu = _a.sent();
                        this.fundAdminRoutingService.pushRoutes(menu);
                        this.menu = menu;
                        if (!legacyUrl) {
                            this.router.navigateByUrl('/');
                        }
                        newRouteUrl = this.fundAdminMenuService.getRouteUrl(legacyUrl);
                        if (!newRouteUrl) {
                            this.router.navigateByUrl('/');
                        }
                        this.globals.language = currentLang;
                        this.router.navigateByUrl(newRouteUrl);
                        return [2 /*return*/];
                }
            });
        }); };
        this.menu = this.fundAdminMenuService.getMenu();
        this.i18n = additionalI18n;
        this.showDashboard = false;
    }
    NavbarComponent.prototype.ngOnInit = function () {
    };
    NavbarComponent.prototype.ngAfterViewInit = function () {
        this.renderer.setProperty(this.sgwtAccountCenter.nativeElement, 'language', this.globals.language);
        this.sgwtAccountCenter.nativeElement.addEventListener('sgwt-account-center--language-changed', this.onLanguageChanged);
    };
    NavbarComponent.prototype.ngOnDestroy = function () {
        this.sgwtAccountCenter.nativeElement.removeEventListener('sgwt-account-center--language-changed', this.onLanguageChanged);
    };
    Object.defineProperty(NavbarComponent.prototype, "serviceName", {
        get: function () {
            return this.globals.EnvParam("apparence_site_title" /* APPARENCE_SITE_TITLE */);
        },
        enumerable: true,
        configurable: true
    });
    NavbarComponent.prototype.onClickNavbarLink = function () {
        var navbarDisplay = this.jquery('#navbar-toggler').css('display');
        if (navbarDisplay === 'block') {
            this.jquery('#navbar').collapse('hide');
        }
    };
    return NavbarComponent;
}());
export { NavbarComponent };
