import { Router } from '@angular/router';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Globals } from 'src/app/globals';
import * as i0 from "@angular/core";
import * as i1 from "../../../globals";
import * as i2 from "@angular/router";
var FundAdminRouteGuard = /** @class */ (function () {
    function FundAdminRouteGuard(globals, router) {
        this.globals = globals;
        this.router = router;
    }
    FundAdminRouteGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        if (this.globals.appLoaded) {
            return of(true);
        }
        return this.globals.appLoaded$
            .pipe(map(function (x) {
            _this.router.navigateByUrl(state.url);
            return true;
        }));
    };
    FundAdminRouteGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FundAdminRouteGuard_Factory() { return new FundAdminRouteGuard(i0.ɵɵinject(i1.Globals), i0.ɵɵinject(i2.Router)); }, token: FundAdminRouteGuard, providedIn: "root" });
    return FundAdminRouteGuard;
}());
export { FundAdminRouteGuard };
