/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./fund-details-forced-prices.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./fund-details-forced-prices.component";
var styles_FundDetailsForcedPricesComponent = [i0.styles];
var RenderType_FundDetailsForcedPricesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FundDetailsForcedPricesComponent, data: {} });
export { RenderType_FundDetailsForcedPricesComponent as RenderType_FundDetailsForcedPricesComponent };
export function View_FundDetailsForcedPricesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "funddetails-widgets row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "funddetails-column col-xl-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "gca-forced-prices-grid", [["class", "funddetails-widget-100 mb-4 mt-4"]], [[8, "apiUrl", 0], [8, "inputData", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fundServicesApiUrl; var currVal_1 = _co.inputData; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_FundDetailsForcedPricesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-fund-details-forced-prices", [], null, null, null, View_FundDetailsForcedPricesComponent_0, RenderType_FundDetailsForcedPricesComponent)), i1.ɵdid(1, 638976, null, 0, i2.FundDetailsForcedPricesComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FundDetailsForcedPricesComponentNgFactory = i1.ɵccf("app-fund-details-forced-prices", i2.FundDetailsForcedPricesComponent, View_FundDetailsForcedPricesComponent_Host_0, { inputData: "inputData" }, {}, []);
export { FundDetailsForcedPricesComponentNgFactory as FundDetailsForcedPricesComponentNgFactory };
