import { Routes } from '@angular/router';
import { FundAdminRouteGuard } from './core/guards';
import { DashboardComponent, FundDetailsComponent, IframePageComponent, SplashComponent } from './shared/components';
import { ReportingPereComponent } from './shared/components/reporting-pere/reporting-pere.component';
var ɵ0 = function () {
    return import("./spa-host/spa-host.module.ngfactory").then(function (m) { return m.SpaHostModuleNgFactory; });
}, ɵ1 = { app: '@funds-factsheet' };
export var ROUTES = [
    {
        path: "mail" /* Mail */,
        component: IframePageComponent,
        canActivate: [FundAdminRouteGuard]
    },
    {
        path: "reporting/pere" /* ReportingPere */,
        component: ReportingPereComponent,
        canActivate: [FundAdminRouteGuard]
    },
    {
        path: "" /* Homepage */,
        component: SplashComponent,
        canActivate: [FundAdminRouteGuard]
    },
    {
        path: "dashboard" /* Dashboard */,
        component: DashboardComponent,
        canActivate: [FundAdminRouteGuard]
    },
    {
        path: "fund-details" /* FundDetails */,
        component: FundDetailsComponent,
        canActivate: [FundAdminRouteGuard]
    },
    {
        path: "funds-factsheet" /* FundsFactsheet */,
        children: [
            {
                path: '**',
                loadChildren: ɵ0,
                data: ɵ1
            }
        ]
    },
    { path: '**', component: SplashComponent, canActivate: [FundAdminRouteGuard] }
];
var AppRootingModule = /** @class */ (function () {
    function AppRootingModule() {
    }
    return AppRootingModule;
}());
export { AppRootingModule };
export { ɵ0, ɵ1 };
