<div class="splash-page text-center" *ngIf="IsFundAdm">
  <div class="splash-banner">
    <p translate>Splash_Welcome</p>
    <p class="banner-desc">
      <b>SG <i>|</i> MARKETS </b> <span translate>Splash_Banner_Desc</span>
    </p>
  </div>

  <div class="splash-content container-fluid">
    <div class="row">
      <div class="col-lg-7 left">
        <div class="info">
          <span translate>Splash_Left_Title</span>
          <ul>
            <li translate>Splash_Left_Info_1</li>
            <li translate>Splash_Left_Info_2</li>
            <li translate>Splash_Left_Info_3</li>
            <li translate>Splash_Left_Info_4</li>
            <li translate>Splash_Left_Info_5</li>
          </ul>
        </div>
        <div class="badges row">
          <div class="col-sm-4">
            <i class="icon icon-xl">apps</i>
            <!-- <img src="../../../assets/home/ico1.png" /> -->
            <span translate>Splash_Badge_1_Text</span>
          </div>
          <div class="col-sm-4">
            <i class="icon icon-xl">notifications_active</i>
            <!-- <img src="../../../assets/home/ico2.png" style="width: 43px;height: 39px;margin-bottom: 12px;"/> -->
            <span translate>Splash_Badge_2_Text</span>
          </div>
          <div class="col-sm-4">
            <i class="icon icon-xl">event_available</i>
            <!-- <img src="../../../assets/home/ico3.png" /> -->
            <span translate>Splash_Badge_3_Text</span>
          </div>
        </div>
      </div>
      <div class="col-lg-5">
        <img
          src="../../../assets/home/phone_view.png"
          style="width: 100px; margin: 30px 0px;"
        />
        <p translate>
          <span class="red-bold" translate>Splash_Right_Text_1</span>&nbsp;<span
            translate
            >Splash_Right_Text_2</span
          >&nbsp;<b translate>Splash_Right_Text_3</b>!
          <span translate>Splash_Right_Text_4</span>
        </p>
        <img
          src="../../../assets/home/pc_phone_view.png"
          style="width: 260px; margin: 30px 0px;"
        />
      </div>
    </div>
  </div>
</div>

<div
  class="splash-flex-block d-flex flex-column justify-content-end pt-3 pb-4 mb-4 mb-lg-5"
  *ngIf="!IsFundAdm"
>
  <div
    class="container px-lg-4 d-flex flex-column justify-content-end"
    style="min-height: 12rem;"
  >
    <div class="row d-flex">
      <div class="col">
        <h1 class="display-2 m-0 line-height-1">
          {{ businessLineTitle | translate }}
        </h1>
      </div>
    </div>
  </div>
  <div class="splash-block-menu container px-lg-4">
    <div class="row">
      <div class="col-md-6 col-xl-4 col-xxl-3 mb-4">
        <div class="card card-bordered">
          <div class="card-body">
            <h2 class="display-4 font-weight-medium text-secondary">
              <p [innerHTML]="businessLineDesc | translate"></p>
            </h2>
          </div>
        </div>
      </div>

      <!-- Block Menu -->
      <div
        class="col-md-12 mb-4"
        [ngClass]="{
          'col-xxl-3 col-xl-3': item.style.size == 's',
          'col-xxl-6 col-xl-4': item.style.size == 'm',
          'col-xxl-12 col-xl-6': item.style.size == 'l',
          'col-xxl-9 col-xl-6': item.style.size == 'l9'
        }"
        *ngFor="let item of items"
      >
        <div class="card bg-lvl2 h-100">
          <div class="card-body">
            <div class="text-large line-height-1 text-secondary">
              {{ item.groupTitle }}
            </div>

            <!-- hasChildren -->
            <h3
              class="card-title mb-0 mt-2 cursor-default"
              *ngIf="item.hasChildren"
            >
              {{ item.subItemTitle }}
            </h3>
            <!-- noChildren -->
            <h3
              class="card-title mb-0 mt-2 cursor-pointer noChildren"
              *ngIf="!item.hasChildren"
            >
              <a [routerLink]="item.children.rewritedUrl">{{
                item.subItemTitle
              }}</a>
            </h3>

            <div class="row mt-4" *ngIf="item.hasChildren">
              <div
                class="mb-1"
                [ngClass]="{
                  'col-xxl-4 col-xl-6 col-md-12':
                    item.style.size == 'l' || item.style.size == 'l9',
                  'col-xxl-6 col-xl-6 col-md-12': item.style.size == 'm',
                  'col-xxl-12 col-xl-12 col-md-12': item.style.size == 's'
                }"
                *ngFor="let i of item.children"
              >
                <a
                  [routerLink]="i.rewritedUrl"
                  class="d-flex justify-content-between btn-link text-primary cursor-pointer mb-1"
                >
                  <span class="menu-label">{{ i.label }}</span>
                  <i class="icon text-secondary">arrow_forwards</i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
