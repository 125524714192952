import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router, ROUTES } from '@angular/router';
import { Globals } from './globals';

@Injectable()
export class ErrorsHandler implements ErrorHandler {
    constructor(
        private globals: Globals
    ) {

    }

    handleError(error: Error | HttpErrorResponse) {
        const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
        if (isIEOrEdge) {
            console.log('It happens: ', error);
            return;
        }

        if (error.message &&
            (
                error.message.indexOf('No activity within') >= 0 ||
                error.message.indexOf('createDataChannel') >= 0
            )
        ) {
            return;
        }

        if (error instanceof HttpErrorResponse) {
            // Server or connection error happened
            if (!navigator.onLine) {
                // Handle offline error
            } else {
                // Handle Http Error (error.status === 403, 404, 500...)
            }
        } else {
            // Handle Client Error (Angular Error, ReferenceError...)
        }
        // Log the error anyway
        console.log('It happens: ', error);
    }
}
