<div class="funddetails-page container-fluid">
  <div class="row">
    <div class="col-12 mt-5 ml-4">
      <div class="display-3" translate>
          FundDetails.Title
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-4 col-md-4 ml-4 mt-4">
      <span class="mr-4 text-large text-secondary" translate>FundDetails.FundSelectorLabel</span>
      <gca-fund-selector style="display:inline-block;width:80%;min-width:200px;" [apiUrl]="fundServicesApiUrl" [fundCode]="fundCode" [pocketCode]="pocketCode"></gca-fund-selector>
    </div>
    <div class="col-lg-5 col-md-6 mt-4">
      <label class="mr-4 text-large text-secondary" translate>FundDetails.DateSelectorLabel</label>
      <gca-nav-date-selector style="display:inline-block;width:80%;min-width:200px;" 
        [apiUrl]="fundServicesApiUrl" 
        [fundCode]="fundCode" 
        [navDate]="navDate"
        [inputData]="datePickerInputData">
    </gca-nav-date-selector>
    </div>
  </div>
  <div class="row">
    <div class="col-12 ml-1">
        <ul class="nav nav-compact-pills text-secondary">
          <li class="nav-item">
            <a class="nav-link" [class.active]="activeTab === fundDetailsTab.Synthesis" (click)="onClickSynthesis()" translate>FundDetails.TebSynthesis</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [class.active]="activeTab === fundDetailsTab.StatemtentOfHoldings" (click)="onClickStatementOfHoldings()" translate>FundDetails.TabStatementOfHoldings</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [class.active]="activeTab === fundDetailsTab.Trades" (click)="onClickTrades()" translate>FundDetails.TabTrades</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [class.active]="activeTab === fundDetailsTab.SubsReds" (click)="onClickSubsReds()" translate>FundDetails.TabSubsReds</a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" [class.active]="activeTab === fundDetailsTab.Cash" (click)="onClickCash()">Cash</a>
          </li> -->
          <li class="nav-item">
            <a class="nav-link" [class.active]="activeTab === fundDetailsTab.ForcedPrices" (click)="onClickForcedPrices()" translate>FundDetails.TabForcedPrices</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [class.active]="activeTab === fundDetailsTab.Compliances" (click)="onClickCompliances()" translate>FundDetails.TabCompliances</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [class.active]="activeTab === fundDetailsTab.NavControl" (click)="onClickNavControl()" translate>FundDetails.TabNAVControl</a>
          </li>
        </ul>
    </div>
  </div>
  <div class="row component-container" [ngSwitch]="activeTab">
    <app-fund-details-synthesis *ngSwitchCase="fundDetailsTab.Synthesis" [inputData]="inputData" class="container-fluid"></app-fund-details-synthesis>
    <app-fund-details-statement-of-holdings *ngSwitchCase="fundDetailsTab.StatemtentOfHoldings" [inputData]="inputData" class="container-fluid"></app-fund-details-statement-of-holdings>
    <app-fund-details-trades *ngSwitchCase="fundDetailsTab.Trades" [inputData]="inputData" class="container-fluid"></app-fund-details-trades>
    <app-fund-details-subs-reds *ngSwitchCase="fundDetailsTab.SubsReds" [inputData]="inputData" class="container-fluid"></app-fund-details-subs-reds>
    <app-fund-details-cash *ngSwitchCase="fundDetailsTab.Cash" class="container-fluid"></app-fund-details-cash>
    <app-fund-details-forced-prices *ngSwitchCase="fundDetailsTab.ForcedPrices" class="container-fluid" [inputData]="inputData"></app-fund-details-forced-prices>
    <app-fund-details-compliances *ngSwitchCase="fundDetailsTab.Compliances" class="container-fluid" [inputData]="inputData"></app-fund-details-compliances>
    <app-fund-details-nav-control *ngSwitchCase="fundDetailsTab.NavControl" class="container-fluid" [inputData]="inputData"></app-fund-details-nav-control>
  </div>
</div>
