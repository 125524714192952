// Same enum in Glass EOS
// @see also https://sgithub.fr.world.socgen/Gallery/gallery-gfa/blob/release/EOS/EOS.CommonEnums/Enums.cs
export enum BusinessLines {
    Undefined = 0,
    FundAdministration = 1, // On SgMarkets
    AssetServicing = 2, // On SgMarkets
    SecuritiesLending = 4,
    CustodyInternational = 8,
    CollatManagement = 16,
    Custody = 32,
    PrivateEquityRealEstate = 64,
    GlobalBrokerDealerServices = 128,
    FdsDashboard = 256
}
