/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./shared/components/loading/loading.component.ngfactory";
import * as i3 from "./shared/components/loading/loading.component";
import * as i4 from "./shared/components/navbar/navbar.component.ngfactory";
import * as i5 from "./shared/components/navbar/navbar.component";
import * as i6 from "./globals";
import * as i7 from "./injection-tokens";
import * as i8 from "@ngx-translate/core";
import * as i9 from "@angular/router";
import * as i10 from "./core/services/gallery-sgmarkets-gfa-menu/fund-admin-menu.service";
import * as i11 from "./core/services/gallery-sgmarkets-gfa-culture/fund-admin-culture.service";
import * as i12 from "./core/services/gallery-sgmarkets-gfa-route/fund-admin-routing.service";
import * as i13 from "./core/services/gallery-sgmarkets-gfa-iframe/fund-admin-iframe.service";
import * as i14 from "./core/directives/scripts-loader/scripts-loader.directive";
import * as i15 from "./core/services/gallery-sgmarkets-gfa-widgets/fund-admin-widgets.service";
import * as i16 from "./shared/components/unauthorized/unauthorized.component.ngfactory";
import * as i17 from "./shared/components/unauthorized/unauthorized.component";
import * as i18 from "./shared/components/servererror/servererror.component.ngfactory";
import * as i19 from "./shared/components/servererror/servererror.component";
import * as i20 from "@angular/common";
import * as i21 from "./app.component";
import * as i22 from "angulartics2/piwik";
import * as i23 from "@angular/platform-browser";
import * as i24 from "./core/services/gallery-sgmarkets-gfa-user/fund-admin-user.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loading", [], null, null, null, i2.View_LoadingComponent_0, i2.RenderType_LoadingComponent)), i1.ɵdid(1, 49152, null, 0, i3.LoadingComponent, [], null, null)], null, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navbar", [], null, null, null, i4.View_NavbarComponent_0, i4.RenderType_NavbarComponent)), i1.ɵdid(1, 4440064, null, 0, i5.NavbarComponent, [i6.Globals, i7.JQUERY_TOKEN, i1.Renderer2, i8.TranslateService, i9.Router, i10.FundAdminMenuService, i11.FundAdminCultureService, i12.FundAdminRoutingService, i13.FundAdminIframeService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["appScriptsLoader", ""], ["class", "flex-grow"]], null, null, null, null, null)), i1.ɵdid(3, 81920, null, 0, i14.ScriptsLoaderDirective, [i6.Globals, i15.FundAdminWidgetsService, i1.ElementRef], null, null), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i9.RouterOutlet, [i9.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "sgwt-help-center", [["default-send-to", "service.gap-sgss@sgss.socgen.com"], ["id", "sgwt-help-center"], ["sg-connect-support", "sg-connect-v2"]], [[1, "application-id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "sgwt-splash-screen", [["id", "my-splash-screen"]], [[1, "application", 0]], null, null, null, null))], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); _ck(_v, 5, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.applicationId; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.applicationId; _ck(_v, 7, 0, currVal_1); }); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-unauthorized", [], null, null, null, i16.View_UnauthorizedComponent_0, i16.RenderType_UnauthorizedComponent)), i1.ɵdid(1, 114688, null, 0, i17.UnauthorizedComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AppComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-servererror", [], null, null, null, i18.View_ServerErrorComponent_0, i18.RenderType_ServerErrorComponent)), i1.ɵdid(1, 114688, null, 0, i19.ServerErrorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { footer: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(2, 16384, null, 0, i20.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(4, 16384, null, 0, i20.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_3)), i1.ɵdid(6, 16384, null, 0, i20.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_4)), i1.ɵdid(8, 16384, null, 0, i20.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, [[1, 0], ["miniFooter", 1]], null, 0, "sgwt-mini-footer", [["accessibility-compliance", "none"], ["contact-us", "{\n      'mail': 'service.gap-sgss@sgss.socgen.com'\n    }"], ["container", "container-fluid"], ["cookies-consent", ""], ["design", "white"], ["id", "fundadmin-footer"], ["legal-notices", "{'en':[{'label': 'Descriptions of services', 'value': 'javascript:fetchLegalDocumentEvent()', 'openInSamePage':true}], \n    'fr':[{'label': 'Descriptions de services', 'value': 'javascript:fetchLegalDocumentEvent()', 'openInSamePage':true}]}"], ["mode", "sg-markets"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.appLoaded && !_co.hasErrors); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.appLoaded && !_co.hasErrors); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.hasError("401"); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.hasError("500"); _ck(_v, 8, 0, currVal_3); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, [["window", "onLegalNoticeDownloadClicked"]], function (_v, en, $event) { var ad = true; if (("window:onLegalNoticeDownloadClicked" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onLegalNoticeDownload() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 4440064, null, 0, i21.AppComponent, [i22.Angulartics2Piwik, i6.Globals, i23.Title, i8.TranslateService, i24.FundAdminUserService, i11.FundAdminCultureService, i10.FundAdminMenuService, i12.FundAdminRoutingService, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i21.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
