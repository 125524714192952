export enum FundDetailsTab {
    Synthesis,
    StatemtentOfHoldings,
    Trades,
    SubsReds,
    Cash,
    ForcedPrices,
    Compliances,
    NavControl
}
