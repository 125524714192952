import { MenuItemType } from './MenuItemType.model';
var MenuItem = /** @class */ (function () {
    function MenuItem(_label, _url, _tracks, _type) {
        this.type = MenuItemType.Legacy;
        this.children = [];
        this.tracks = [];
        this.label = _label;
        this.url = _url;
        this.tracks = _tracks; // path position tracable
        this.type = _type;
    }
    Object.defineProperty(MenuItem.prototype, "hasChild", {
        get: function () {
            return this.tracks && this.tracks.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuItem.prototype, "maxChildWidth", {
        get: function () {
            var cntEles = this.children && this.children.length > 0 ? this.children
                .map(function (c) { return c.children.length; })
                .reduce(function (max, n) { return n > max ? n : max; }) : 0;
            cntEles = cntEles > 4 ? 4 : cntEles;
            cntEles = cntEles <= 0 ? 1 : cntEles;
            return cntEles;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuItem.prototype, "maxChildClass", {
        get: function () {
            return "max-eles-" + this.maxChildWidth;
        },
        enumerable: true,
        configurable: true
    });
    return MenuItem;
}());
export { MenuItem };
