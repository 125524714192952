<nav class="navbar navbar-expand-lg navbar-light bg-white gallery-nav">
  <div
    class="container-flex d-flex align-items-center justify-content-between w-100"
  >
    <a routerLink="/" class="navbar-brand">
      <!-- <img alt="Logo Société Générale" src="./assets/logo_full.svg" height="32" width="165" class="d-none d-lg-block" > -->
      <img
        alt="Logo Société Générale"
        src="./assets/logo.svg"
        height="32"
        width="32"
        class=""
      />
    </a>

    <div class="collapse navbar-collapse container-fluid" id="navbar" >
      <ul
        class="navbar-nav align-items-left"
        [ngStyle]="{ display: false ? 'none' : 'flex' }"
      >
        <!-- dashboard -->
        <ng-template *ngIf="showDashboard">
          <li class="nav-item top-item">
            <a
              class="nav-link __cursor_pointer"
              role="button"
              routerLink="/"
              (click)="onClickNavbarLink()"
              translate
              >Menu_Home</a
            >
          </li>
        </ng-template>

        <li
          class="nav-item top-item"
          [class.dropdown]="item.children && item.children.length > 0"
          *ngFor="let item of menu"
        >
          <a
            *ngIf="item.children && item.children.length > 0"
            class="nav-link dropdown-toggle __cursor_pointer"
            id="navbarDropdown_{{ item.position }}"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="true"
          >
            {{ item.label }}
          </a>
          <a
            *ngIf="!item.children || item.children.length <= 0"
            class="nav-link __cursor_pointer"
            id="navbarDropdown_{{ item.position }}"
            (click)="onClickNavbarLink()"
            [routerLink]="item.rewritedUrl"
          >
            {{ item.label }}
          </a>

          <!-- arrow badge -->
          <div class="arrow" *ngIf="!item.url"></div>
          <!-- sub-level -->
          <div *ngIf="!item.url" class="dropdown-menu pt-4">
            <div
              [class]="'row sub-item pl-4 pr-4 pb-2 ' + item.maxChildClass"
              *ngFor="let subitem of item.children"
            >
              <!-- sub-level title -->

              <h4 class="col-12 font-weight-bold" *ngIf="!subitem.url">
                {{ subitem.label }}
              </h4>
              <!-- sub-level link -->
              <h4
                class="col-12 font-weight-bold sub-link"
                [class.mono]="subitem.hasChild"
                *ngIf="subitem.url"
              >
                <a
                  (click)="onClickNavbarLink()"
                  [routerLink]="subitem.rewritedUrl"
                >
                  {{ subitem.label }}
                </a>
              </h4>

              <!-- mono-level -->
              <div
                [class]="
                  'col-lg-' + 12 / item.maxChildWidth + ' pb-2 mono-link'
                "
                *ngFor="let monoitem of subitem.children"
              >
                <a
                  (click)="onClickNavbarLink()"
                  [routerLink]="monoitem.rewritedUrl"
                  class="font-weight-bold"
                  >{{ monoitem.label }}
                </a>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="d-flex justify-content-end align-items-center">
      <button
        class="navbar-toggler"
        id="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbar"
        aria-controls="navbar-large"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="icon icon-md">menu</i>
      </button>

      <sgwt-account-center
        #sgwtAccountCenter
        available-languages="en,fr,it"
        show-sign-in-button="true"
        [i18n]="i18n"
        authentication="sg-connect-v2"
        mode="sg-markets"
      >
      </sgwt-account-center>

      <svg
        class="d-none d-md-block overflow-visible ml-3"
        width="135"
        height="32"
      >
        <image
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xlink:href="assets/sg_market.svg"
          x="0"
          y="0"
          height="16"
          width="132"
        ></image>
        <!--Change here name of services-->
        <text class="font-family-display" x="0" y="32" height="16">
          {{ serviceName }}
        </text>
      </svg>
    </div>
  </div>
</nav>
