import { IMenuItem } from './IMenuItem.model';
import { MenuItemType } from './MenuItemType.model';

export class MenuItem implements IMenuItem {
    constructor(_label: string, _url: string, _tracks: string[], _type: MenuItemType) {
        this.label = _label;
        this.url = _url;
        this.tracks = _tracks; // path position tracable
        this.type = _type;
    }

    label: string;
    url: string;
    rewritedUrl: string;
    type: MenuItemType = MenuItemType.Legacy;
    children: IMenuItem[] = [];
    tracks: string[] = [];

    public get hasChild(): boolean {
        return this.tracks && this.tracks.length > 0;
    }

    public get maxChildWidth(): number {
        let cntEles = this.children && this.children.length > 0 ? this.children
                   .map((c: IMenuItem) => c.children.length)
                   .reduce((max, n) => n > max ? n : max) : 0 ;

        cntEles = cntEles > 4 ? 4 : cntEles;
        cntEles = cntEles <= 0 ? 1 : cntEles;

        return cntEles;
    }

    public get maxChildClass(): string {
        return `max-eles-${this.maxChildWidth}`;
    }
}
