import { Injectable } from '@angular/core';
import { Route, Router, Routes } from '@angular/router';

import { Subject } from 'rxjs';

import { IMenuItem, MenuItemType } from 'src/app/models';

import { Routes_Paths } from 'src/app/models/constants/RoutePaths';
import { LegacyIframeComponent } from 'src/app/shared/components/legacy-iframe/legacy-iframe.component';
import { FundAdminRouteGuard } from '../../guards';

@Injectable({
  providedIn: 'root'
})
export class FundAdminRoutingService {
  private routeLoadedSource = new Subject<boolean>();
  public routeLoaded$ = this.routeLoadedSource.asObservable();

  constructor(private router: Router) { }

  public initRouteLoaded() {
    this.routeLoadedSource.next(false);
  }

  public pushRoutes(menu: IMenuItem[]) {
    const routes = this.buildRoutes(menu);
    this.replaceHomeWithDashboard(menu);
    this.router.config.unshift(...routes);
    this.routeLoadedSource.next(true);
  }

  public popRoutes(menu: IMenuItem[]) {
    const routes = this.buildRoutes(menu).map(r => r.path);
    this.router.config = this.router.config.filter(
      route => routes.indexOf(route.path) < 0
    );
    this.routeLoadedSource.next(true);
  }

  private replaceHomeWithDashboard(menus: IMenuItem[]) {
    const hasDashboard = menus && menus.find(m => m.url && m.url.toLowerCase().includes(Routes_Paths.Dashboard.toLowerCase()));
    // this.router.config.find(r => r.path === Routes_Paths.Dashboard);
    if (hasDashboard) {
      // inject redirect route
      const newDashboardRoute = {
        path: Routes_Paths.Homepage,
        redirectTo: Routes_Paths.Dashboard,
        canActivate: [FundAdminRouteGuard]
      };
      this.router.config = this.router.config.filter(r => r.path !== Routes_Paths.Homepage && r.path !== '**');
      this.router.config.unshift(newDashboardRoute);

      // Updates '**' route to redirect to Dashboard.
      const doubleStarRoute = {
        path: '**',
        redirectTo: Routes_Paths.Dashboard,
        canActivate: [FundAdminRouteGuard]
      };
      this.router.config.push(doubleStarRoute);
    } else {
      // clean dashboard & funddetails routes
      this.router.config = this.router.config.filter(r =>
        r.path !== Routes_Paths.Dashboard &&
        r.path !== Routes_Paths.FundDetails);
    }
  }

  private buildRoutes(menu: IMenuItem[]) {
    const routes: Route[] = [];

    for (const item of menu) {

      if (item.url && item.type === MenuItemType.Legacy) {
        routes.push({
          path: item.rewritedUrl,
          component: LegacyIframeComponent,
          canActivate: [FundAdminRouteGuard]
        });
      }

      if (item.children && item.children.length > 0) {
        routes.unshift(...this.buildRoutes(item.children));
      }
    }

    return routes;
  }
}
