import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';

import * as _ from 'lodash';

import { NavModificationResult, ValidationStatus } from 'src/app/shared/models';

@Component({
  selector: 'app-dashboard-notification',
  templateUrl: './dashboard-notification.component.html',
  styleUrls: ['./dashboard-notification.component.scss']
})
export class DashboardNotificationComponent implements OnInit, OnChanges {

  @Input() display: boolean = false;
  @Input() navModificationResult: NavModificationResult;
  @Output() hidden: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    const displayChange = changes["display"];

    if (displayChange && displayChange.currentValue) {
      // add a timeout to close automatically
      _.delay(() => this.onClickClose(), 5000);
    }
  }

  get displayClass() {
    return this.display ? 'show' : 'hide';
  }

  get colorClass() {
    return this.navModificationResult && this.navModificationResult.statusCode >= 200 && this.navModificationResult.statusCode < 300
    ? 'toast-success'
    : 'toast-danger';
  }

  get className() {
    return `toast ${this.colorClass} fade ${this.displayClass}`;
  }

  get title() {
    if (!this.navModificationResult) {
      return '';
    }

    if (this.navModificationResult.fundNames.length > 1) {
      return 'NAV validation result'
    }

    return this.navModificationResult.navStatus === ValidationStatus.Validated
      ? 'NAV successfully validated'
      : 'NAV successfully rejected';
  }

  get message() {
    if (!this.navModificationResult) {
      return '';
    }

    return `Your portfolio ${this.navModificationResult.fundNames[0]} has been ${this.navModificationResult.navStatus === ValidationStatus.Validated ? 'validated' : 'rejected'}.`;
  }

  onClickClose() {
    this.display = false;
    this.hidden.emit(!this.display);
  }

}
