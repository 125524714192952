/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loading.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./loading.component";
var styles_LoadingComponent = [i0.styles];
var RenderType_LoadingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoadingComponent, data: {} });
export { RenderType_LoadingComponent as RenderType_LoadingComponent };
export function View_LoadingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "app-loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "loading-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "Logo Soci\u00E9t\u00E9 G\u00E9n\u00E9rale"], ["class", "loading-logo"], ["height", "32"], ["src", "./assets/logo.svg"], ["width", "32"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "lds-dual-ring"]], null, null, null, null, null))], null, null); }
export function View_LoadingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loading", [], null, null, null, View_LoadingComponent_0, RenderType_LoadingComponent)), i1.ɵdid(1, 49152, null, 0, i2.LoadingComponent, [], null, null)], null, null); }
var LoadingComponentNgFactory = i1.ɵccf("app-loading", i2.LoadingComponent, View_LoadingComponent_Host_0, {}, {}, []);
export { LoadingComponentNgFactory as LoadingComponentNgFactory };
