import { OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/globals';
import { ValidationStatus } from 'src/app/shared/models';
import { FundAdminNavService } from '../../../core/services';
import { Environment } from '../../../environments/environment';
var DashboardComponent = /** @class */ (function () {
    function DashboardComponent(router, fundAdminNavService, globals) {
        this.router = router;
        this.fundAdminNavService = fundAdminNavService;
        this.globals = globals;
        this.navApiUrl = Environment.host_api_nav;
        this.fundServicesApiUrl = Environment.host_api_fundservices;
        this.displayNotification = false;
        this.navs = null;
    }
    Object.defineProperty(DashboardComponent.prototype, "navsAvailable", {
        get: function () {
            return this.navs && this.navs.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardComponent.prototype, "totalNavsRefused", {
        get: function () {
            return this.navs ? this.navs.filter(function (navInfo) { return navInfo.navInfoDetails[0].validationStatus === ValidationStatus.Refused; }).length : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardComponent.prototype, "totalNavsValidated", {
        get: function () {
            return this.navs ? this.navs.filter(function (navInfo) { return navInfo.navInfoDetails[0].validationStatus === ValidationStatus.Validated; }).length : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardComponent.prototype, "totalNavsWaiting", {
        get: function () {
            return this.navs ?
                this.navs.filter(function (navInfo) { return navInfo.navInfoDetails[0].validationStatus === ValidationStatus.WaitingForValuation; }).length : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardComponent.prototype, "totalNavsToValidate", {
        get: function () {
            return this.navs ?
                this.navs.filter(function (navInfo) { return navInfo.navInfoDetails[0].validationStatus === ValidationStatus.WaitingForManagerValidation; }).length : 0;
        },
        enumerable: true,
        configurable: true
    });
    DashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.inputData = {
            lang: this.globals.language$.value
        };
        this.navSubscription = this.fundAdminNavService.getNavs().subscribe(function (navs) { return _this.navs = navs; });
    };
    DashboardComponent.prototype.ngOnDestroy = function () {
        this.navSubscription.unsubscribe();
    };
    DashboardComponent.prototype.onNavsListFundSelected = function (eventDetail) {
        this.router.navigate(['/fund-details'], { state: { data: eventDetail } });
    };
    DashboardComponent.prototype.onNavsListNavModified = function (eventDetail) {
        var _this = this;
        this.fundAdminNavService.getNavs().subscribe(function (navs) { return _this.navs = navs; });
        this.navModificationResult = eventDetail;
        this.displayNotification = true;
    };
    DashboardComponent.prototype.onNotificationHidden = function () {
        this.displayNotification = false;
    };
    DashboardComponent.prototype.onClickViewFundDetails = function () {
        this.router.navigate(['/fund-details']);
    };
    return DashboardComponent;
}());
export { DashboardComponent };
