/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./iframe-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../legacy-iframe/lagacy-iframe-renderer/lagacy-iframe-renderer.component.ngfactory";
import * as i3 from "../legacy-iframe/lagacy-iframe-renderer/lagacy-iframe-renderer.component";
import * as i4 from "@angular/common";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./iframe-page.component";
import * as i7 from "@angular/router";
import * as i8 from "../../../globals";
var styles_IframePageComponent = [i0.styles];
var RenderType_IframePageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IframePageComponent, data: {} });
export { RenderType_IframePageComponent as RenderType_IframePageComponent };
function View_IframePageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-lagacy-iframe-renderer", [], null, null, null, i2.View_LagacyIframeRendererComponent_0, i2.RenderType_LagacyIframeRendererComponent)), i1.ɵdid(1, 49152, null, 0, i3.LagacyIframeRendererComponent, [], { iframeUrl: [0, "iframeUrl"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.baseUrl + i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.resource))); _ck(_v, 1, 0, currVal_0); }, null); }
function View_IframePageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "message-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "alert alert-outline-primary"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(2, 8536064, null, 0, i5.TranslateDirective, [i5.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["IFramePage.NotFound"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_IframePageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_IframePageComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["notfound", 2]], null, 0, null, View_IframePageComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.resource)); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_IframePageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-iframe-page", [], null, null, null, View_IframePageComponent_0, RenderType_IframePageComponent)), i1.ɵdid(1, 114688, null, 0, i6.IframePageComponent, [i7.ActivatedRoute, i8.Globals], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IframePageComponentNgFactory = i1.ɵccf("app-iframe-page", i6.IframePageComponent, View_IframePageComponent_Host_0, {}, {}, []);
export { IframePageComponentNgFactory as IframePageComponentNgFactory };
