import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  Renderer2
} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Angulartics2Piwik } from 'angulartics2/piwik';

import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import {
  FundAdminCultureService,
  FundAdminMenuService,
  FundAdminRoutingService,
  FundAdminUserService
} from './core/services';
import { Globals } from './globals';
import {
  BusinessLines,
  Envs,
  ErrorCodes,
  ParameterKeys
} from './models/constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private appLoadedSubscription: Subscription;
  private errorSubscription: Subscription;

  appLoaded = false;
  applicationId: string;
  errors: ErrorCodes[] = [];

  @ViewChild('miniFooter', { static: true })
  footer: ElementRef;

  constructor(
    private angulartics2Piwik: Angulartics2Piwik,
    private globals: Globals,
    private titleService: Title,
    private translate: TranslateService,
    private fundAdminUserService: FundAdminUserService,
    private fundAdminCultureService: FundAdminCultureService,
    private fundAdminMenuService: FundAdminMenuService,
    private fundAdminRoutingService: FundAdminRoutingService,
    private renderer2: Renderer2
  ) {}

  async ngOnInit() {
    this.angulartics2Piwik.startTracking();
    // tslint:disable-next-line:arrow-parens
    this.appLoadedSubscription = this.globals.appLoaded$.subscribe((_) => {
      this.appLoaded = _;
    });

    // tslint:disable-next-line:arrow-parens
    this.errorSubscription = this.globals.error$.subscribe((_) => {
      this.errors = [_];
    });

    this.loadApp();
    this.titleService.setTitle(
      this.globals.EnvParam(ParameterKeys.APPARENCE_SITE_TITLE) as string
    );
  }

  private async loadApp() {
    try {
      // sgwt application id
      this.applicationId = this.globals.EnvParam(
        ParameterKeys.APPLICATION_ID
      ) as string;

      // Load default language config
      const lang = await this.fundAdminCultureService.initCulture();
      this.translate.use(lang);
      (window as any).lang = lang;
      this.globals.language = lang;

      // SGMA check call
      const user = await this.fundAdminUserService.checkService();
      this.globals.user = user;

      // Build router
      const menu = await this.fundAdminMenuService.buildMenu(lang);
      this.fundAdminRoutingService.pushRoutes(menu);

      // Set Flag AppLoaded
      this.globals.appLoaded = true;

      this.addAnalyticsWebComponent();

    } catch (e) {
      await this.unauthorized();
    }
  }

  private async unauthorized() {
    // Show unauthorized component
    if (
      !this.globals.user ||
      this.globals.EnvParam(ParameterKeys.FUNC_STRICT401)
    ) {
      let redirectUrl = '';
      // tslint:disable-next-line:prefer-conditional-expression
      if (
        this.globals.Environment.current === Envs.PProd ||
        this.globals.Environment.current === Envs.Prod
      ) {
        redirectUrl = `https://shared.sgmarkets.com/unauthorized/?redirectUri=${location.protocol}//${window.location.hostname}`;
      } else {
        redirectUrl = `https://shared-uat.fr.world.socgen/unauthorized/?redirectUri=${location.protocol}//${window.location.hostname}`;
      }
      window.location.replace(redirectUrl);

      // this.globals.emitError(ErrorCodes.Error401); // Global redirection sgmarket
    }
  }

  get hasErrors(): boolean {
    return this.errors.length !== 0;
  }

  hasError(errCode: string): boolean {
    const exsited = this.errors.some((err: ErrorCodes) => errCode === err);

    return exsited;
  }

  async ngAfterViewInit() {
    await this.accessibilityInit();
  }
  private async accessibilityInit() {
    const accebilityDict = {
      AssetServicing: {
        fr: '/assets/accessibility-docs/SOCIETE-GENERALE-SG Markets Asset Servicing-Declaration-Accessibilite-2021-03.pdf',
        en: '/assets/accessibility-docs/SOCIETE-GENERALE-SG Markets Asset Servicing-Declaration-Accessibility-2021-03.pdf',
        it: '/assets/accessibility-docs/SOCIETE-GENERALE-SG Markets Asset Servicing-Declaration-Accessibility-2021-03.pdf'
      },
      FundAdministration: {
        fr: '/assets/accessibility-docs/SOCIETE-GENERALE-SG Markets Fund Administration-Declaration-Accessibilite-2021-03.pdf',
        en: '/assets/accessibility-docs/SOCIETE-GENERALE-SG Markets Fund Administration-Declaration-Accessibility-2021-03.pdf',
        it: '/assets/accessibility-docs/SOCIETE-GENERALE-SG Markets Fund Administration-Declaration-Accessibility-2021-03.pdf'
      },
      SecuritiesLending: {
        fr: '/assets/accessibility-docs/SOCIETE-GENERALE-SG Markets Securities Lending-Declaration-Accessibilite-2021-03.pdf',
        en: '/assets/accessibility-docs/SOCIETE-GENERALE-SG Markets Securities Lending-Declaration-Accessibility-2021-03.pdf',
        it: '/assets/accessibility-docs/SOCIETE-GENERALE-SG Markets Securities Lending-Declaration-Accessibility-2021-03.pdf'
      }
    };
    const businessLine = this.globals.EnvParam(
      ParameterKeys.BUSINESS_LINE
    ) as BusinessLines;
    this.globals.language$.subscribe((lang) => {
      const accessibilityLink =
        accebilityDict[BusinessLines[businessLine]][lang];
      this.footer.nativeElement.accessibilityLink = accessibilityLink;
    });
  }

  @HostListener('window:onLegalNoticeDownloadClicked')
  onLegalNoticeDownload() {
    const legalNoticeTopicId =
      this.globals.Environment.helpcenter_topic_id_documents;
    var widget = document.querySelector('sgwt-help-center') as any;
    if (widget) {
      legalNoticeTopicId ? widget.category(legalNoticeTopicId) : widget.open();
    }
  }

  /**
   * add sgwt-web-analytics component to body
   */
  private addAnalyticsWebComponent(): void {
    // matomo config
    const matomoSiteId  = this.globals.EnvParam(ParameterKeys.PIWIK_SITE_ID);
    const matomoTrackUrl = this.globals.EnvParam(ParameterKeys.PIWIK_URL);
    const element = document.createElement('sgwt-web-analytics', { });
    element.setAttribute("site-id", `${matomoSiteId}`);
    element.setAttribute("base-url", `${matomoTrackUrl}`);
    this.renderer2.appendChild(document.body, element);
  }
  ngOnDestroy() {
    this.appLoadedSubscription.unsubscribe();
    this.errorSubscription.unsubscribe();
  }
}
