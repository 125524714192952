import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FundAdminIframeService } from 'src/app/core/services/gallery-sgmarkets-gfa-iframe/fund-admin-iframe.service';
import { FundAdminMenuService } from 'src/app/core/services/gallery-sgmarkets-gfa-menu/fund-admin-menu.service';

@Component({
  selector: 'app-legacy-iframe',
  templateUrl: './legacy-iframe.component.html',
  styleUrls: ['./legacy-iframe.component.scss']
})
export class LegacyIframeComponent implements OnInit, OnDestroy {
  private refreshIframeSubscription: Subscription;
  public iframeUrl: string;

  constructor(
    private router: Router,
    private fundAdminMenuService: FundAdminMenuService,
    private fundAdminIframeService: FundAdminIframeService
  ) {
    this.refreshIframeSubscription = this.fundAdminIframeService.refreshIframe$.subscribe(_ => this.refreshIframe());
  }

  ngOnInit() {
    this.refreshIframe();
  }

  ngOnDestroy() {
    this.refreshIframeSubscription.unsubscribe();
  }

  private refreshIframe() {
    const iframeUrl = this.getIframeUrl();
    if (!iframeUrl) {
      this.router.navigateByUrl('/');
    }
    this.iframeUrl = iframeUrl;
  }

  private getIframeUrl() {
    return this.fundAdminMenuService.getLegacyUrl(this.router.routerState.snapshot.url.substr(1));
  }

}
