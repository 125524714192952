var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { enableProdMode, NgZone } from '@angular/core';
import { Environment } from 'src/app/environments/environment';
import { setupSGWTConnect } from '@sgwt/connect-angular';
import { start as singleSpaStart } from 'single-spa';
import { PlatformLocation } from '@angular/common';
import { getSingleSpaExtraProviders } from 'single-spa-angular';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (Environment.current === 3 /* Prod */) {
    enableProdMode();
}
singleSpaStart();
var sgwtConnectEndpoint = Environment.current === 3 /* Prod */ || Environment.current === 2 /* PProd */
    ? "https://sso.sgmarkets.com/sgconnect"
    : "https://sgconnect-hom.fr.world.socgen/sgconnect";
var scopes = Environment["sgconnect_scope" /* SGCONNECT_SCOPE */];
var defaultScopes = ['openid', 'profile', 'mail'];
var sgwtConnect = setupSGWTConnect({
    authorization_endpoint: sgwtConnectEndpoint,
    client_id: Environment["sgconnect_client_id" /* SGCONNECT_CLIENT_ID */],
    scope: __spread(defaultScopes, scopes).join(' '),
    acr_values: Environment["sgconnect_acr" /* SGCONNECT_ACR */]
});
var widget = document.querySelector('sgwt-connect');
if (widget) {
    // When the code is executed, the widget may not have been initialized. So, we need to check that, otherwise calling
    // `widget.setSgwtConnectInstance()` will throw an error.
    if (typeof widget.setSgwtConnectInstance === 'undefined') {
        // Widget is not initialized yet, so we will wait the event that indicates the widget is ready...
        // tslint:disable-next-line:no-shadowed-variable
        var handleSgwtConnectReady_1 = function () {
            widget.setSgwtConnectInstance(sgwtConnect);
            // Remove the event listener
            widget.removeEventListener('sgwt-connect--ready', handleSgwtConnectReady_1);
        };
        widget.addEventListener('sgwt-connect--ready', handleSgwtConnectReady_1);
    }
    else {
        // Widget is initialized...
        widget.setSgwtConnectInstance(sgwtConnect);
    }
}
var wrapper = document.querySelector('sgwt-connect');
var sgwtConnectWrapper = wrapper.sgwtConnect;
if (sgwtConnectWrapper.isAuthorized()) {
    /*
     * Bootstrap our Angular app with a top level NgModule
     */
    var appId_1 = 'container-app';
    __NgCli_bootstrap_2.platformBrowser(getSingleSpaExtraProviders())
        .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
        .then(function (module) {
        NgZone.isInAngularZone = function () {
            // @ts-ignore
            return window.Zone.current._properties[appId_1] === true;
        };
        var rootPlatformLocation = module.injector.get(PlatformLocation);
        var rootZone = module.injector.get(NgZone);
        // tslint:disable-next-line:no-string-literal
        rootZone['_inner']._properties[appId_1] = true;
        rootPlatformLocation.setNgZone(rootZone);
    })
        .catch(function (err) { return console.log(err); });
}
else if (sgwtConnectWrapper.getAuthorizationError()) {
    document.body.innerHTML = "\n   <div class=\"alert alert-danger\" role=\"alert\">\n       Authorization error: " + sgwtConnectWrapper.getAuthorizationError() + ".\n   </div>\n   ";
}
else {
    sgwtConnectWrapper.requestAuthorization();
}
